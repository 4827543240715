import { useEffect, useState } from 'react';
import { BiBold, BiUnderline, BiShow,  BiTrash } from 'react-icons/bi';
import { AiOutlineAlignLeft, AiOutlineAlignCenter, AiOutlineAlignRight } from 'react-icons/ai';
import useContextMenu from "../../../Hooks/useContextMenu";

const StylingMenuForList = ({  
  body,
  pageData,
  setUpdatedPageData,

  selectedElement,
  setSelectedElement,
  setIsElementSelected,
  setIsListElementSelected,

  elementId, 
  elementType,
  showLink,
  setShowLink 
 }) => {
const [index1, index2] = [selectedElement?.index1, selectedElement?.index2];
  const [bold, setBold] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [alignment, setAlignment] = useState('left');

  // eslint-disable-next-line no-unused-vars
  const [anchorPoint, show] = useContextMenu(elementId);

  const handleColorChange = (e) => {
    const color = e.target.value;
    handleStyleChange('color', color, index2);
  };
  const handleFontSizeChange = (e) => {
    const fontSize = e.target.value + 'px';
    handleStyleChange('fontSize', fontSize, index2);
  };


  const handleDeleteElement = () => {
    console.log("handleDeleteElement!!!!!!","index1",index1,"index2",index2,"elementType",elementType)
    const updatedBody = JSON.parse(JSON.stringify(body)); // Create a deep copy
    // Handle delete for quiz answers
    if (elementType === 'quiz')  updatedBody[index1].body.items.splice(index2, 1);
    // Handle delete for oList text
    if (elementType === 'ol' || elementType === 'ul') updatedBody[index1].items.splice(index2, 1);
    setUpdatedPageData({ ...pageData, body: updatedBody });
  };
  
  const handleStyleChange = (style, value, { index1, index2 }) => {
    const updatedBody = JSON.parse(JSON.stringify(pageData.body));
  
    if (
      updatedBody[index1] &&
      (updatedBody[index1].type === 'uList' || updatedBody[index1].type === 'oList' || updatedBody[index1].type === 'quiz')
    ) {
     
      const updatedTextElement = { ...updatedBody[index1] };
 
      if (updatedTextElement.items && updatedTextElement.items[index2]) {
        const updatedTextStyle = { ...updatedTextElement.items[index2].style };
  
        // Check if the style is already applied, then remove it; otherwise, add it
        if (updatedTextStyle[style] === value) {
          delete updatedTextStyle[style];
        } else {
          updatedTextStyle[style] = value;
        }
  
        updatedTextElement.items[index2] = {
          ...updatedTextElement.items[index2],
          style: updatedTextStyle,
        };
  
        updatedBody[index1] = updatedTextElement;
  
        const innerUpdatedPageData = {
          ...pageData,
          body: updatedBody,
        };
  
        setUpdatedPageData(innerUpdatedPageData);
      }

      // for quiz
      if (updatedTextElement.body.items && updatedTextElement.body.items[index2]) {
        const updatedTextStyle = { ...updatedTextElement.body.items[index2].style };
  
        // Check if the style is already applied, then remove it; otherwise, add it
        if (updatedTextStyle[style] === value) {
          delete updatedTextStyle[style];
        } else {
          updatedTextStyle[style] = value;
        }
  
        updatedTextElement.body.items[index2] = {
          ...updatedTextElement.body.items[index2],
          style: updatedTextStyle,
        };
       
        updatedBody[index1] = updatedTextElement;
  
        const innerUpdatedPageData = {
          ...pageData,
          body: updatedBody,
        };
  
        setUpdatedPageData(innerUpdatedPageData);
      }


    }
  };
  
  
  


    
  
  if (!show) return null 
  else
  return (
    <div className="floating-menu" draggable={true} style={{position: 'absolute' , top: 0, right: 0 ,zIndex:"9999"}}>

      
      <label htmlFor="color-picker">
        <span className="hidden-text">Color:</span>
        <input className="color-picker" type="color" id="color-picker" onChange={handleColorChange} />
      </label>
      <label htmlFor="font-size-picker">
        <span className="hidden-text">Font Size:</span>
        <input className="font-size-picker" type="number" id="font-size-picker" min="10" max="50" onChange={handleFontSizeChange} />
      </label>
      <button onClick={() => {
        setBold(!bold);
        handleStyleChange('fontWeight', 'bold', selectedElement);
      }}>
        <BiBold className={bold ? 'active' : ''} />
      </button>
      <button onClick={() => {
        setUnderline(!underline);
        handleStyleChange('textDecoration', 'underline', selectedElement);
      }}>
        <BiBold className={underline ? 'active' : ''} />
      </button>
      <button onClick={() => {
        setAlignment('left');
        handleStyleChange('textAlign', 'left', selectedElement);
      }}>
        <AiOutlineAlignLeft className={alignment === 'left' ? 'active' : ''} />
      </button>
      <button onClick={() => {
        setAlignment('center');
        handleStyleChange('textAlign', 'center', selectedElement);
      }}>
        <AiOutlineAlignCenter className={alignment === 'center' ? 'active' : ''} />
      </button>
      <button onClick={() => {
        setAlignment('right');
        handleStyleChange('textAlign', 'right', selectedElement);
      }}>
        <AiOutlineAlignRight className={alignment === 'right' ? 'active' : ''} />
      </button>
      <button onClick={() => { 
        handleDeleteElement(selectedElement); // Call the delete action
        setSelectedElement({ ...index1 , index2: null });
        setIsListElementSelected(false);
        setIsElementSelected(false);
      }}>
        <BiTrash />
      </button>
      {elementType === 'link' && <BiShow onClick={()=>{setShowLink(!showLink)}}/>}
    </div>
  );


};

export default StylingMenuForList;

import { useState, useEffect } from "react";
import MediaMenu from "../MediaMenu";

const Video = (props) => {
  const {
    element,
    contenteditable,
    index1,
    setIsListElementSelected,
    setSelectedElement,
    setIsElementSelected,
    isElementSelected,
    selectedElement,
    slideSize,
    pageData,
    setUpdatedPageData,
    body,
    handleAddSrc,
    handleDeleteElement
  } = props;

  const { src, alt } = element.video;
  const [size, setSize] = useState({ width: element?.video?.width, height: element?.video?.height });
  const [elementSrc, setElementSrc] = useState(src);
  const [miniVideo, setMiniVideo] = useState({ width: 100, height: 100 });

  useEffect(() => {
    setElementSrc(src);
    // eslint-disable-next-line
  }, [element])

  useEffect(() => {
    const slideSizeOb = document?.querySelector("#page-body")?.getBoundingClientRect();
    console.log(slideSizeOb);
    const baseSize = pageData.pageStyle.baseSize;
    const baseWidth = baseSize?.width ? baseSize.width : slideSizeOb.width;
    const baseHeight = baseSize?.height ? baseSize.height : slideSizeOb.height;
    
    
    const xRatio = baseWidth/ slideSizeOb.width;
    const yRatio = baseHeight / slideSizeOb.height;


    const newWidth = size.width / xRatio;
    const newHeight = size.height / yRatio;  
    setSize({ width: newWidth, height: newHeight });
    if (newWidth < 150) {
      setSize({ width: 150, height: 150 });      
      return;
    }
   
   // eslint-disable-next-line
  },[]);

  useEffect(() => {
    const slideSizeOb = document?.querySelector("#page-body")?.getBoundingClientRect();
    const miniSlideSizeOb = document?.querySelector("#mini-page-body")?.getBoundingClientRect();
    handleChangeSize(size.width, size.height, index1);
    if (miniSlideSizeOb) {
      const xRatio = slideSizeOb.width / miniSlideSizeOb.width;
      const yRatio = slideSizeOb.height / miniSlideSizeOb.height;
      const newMiniWidth = size.width / xRatio;
      const newMiniHeight = size.height / yRatio;
      const tempMiniSize = { width: newMiniWidth, height: newMiniHeight };
      setMiniVideo(tempMiniSize);
    }
// eslint-disable-next-line
  }, [size]);

  


  const handleChangeSize = (width, height, index) => {
    const updatedBody = [...pageData.body];
    const selectedElement = updatedBody[index];

    if (selectedElement && selectedElement.video) {
      const updatedElement = {
        ...selectedElement,
        video:
        {
          ...selectedElement.video,
          width: width,
          height: height,
          miniVIDEO: miniVideo,
        },
      };

      updatedBody[index] = updatedElement;

      const updatedPageData = {
        ...pageData,
        body: updatedBody,
      };

      setUpdatedPageData(updatedPageData);
    } else {
      console.log(`Element at index ${index} does not exist.`);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();

    const initialX = e.clientX - size.width;
    const initialY = e.clientY - size.height;

    const handleMouseMove = (e) => {
      setSize({
        width: e.clientX - initialX,
        height: e.clientY - initialY,
      });
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  
  const handleSubmitGallery = (index, selectedImageName) => {
    const updatedBody = [...pageData.body];
    const selectedElement = updatedBody[index];

    if (selectedElement && selectedElement.video) {
      const updatedElement = {
        ...selectedElement,
        video: {
          ...selectedElement.video,
          src: selectedImageName,
        }

      };

      updatedBody[index] = updatedElement;
      const galleryUpdatedPageData = {
        ...pageData,
        body: updatedBody,
      };
      setUpdatedPageData(galleryUpdatedPageData);
    } else {
      console.log(`Element at index ${index} does not exist or does not have the required structure.`);
    }
  };
 
  const videoDrop = (e, index) => {   
    e.preventDefault();
    const mainSelector = document.querySelector(".content-right");
    const mainSelectorRect = mainSelector?.getBoundingClientRect();
    const x = e.clientX - mainSelectorRect.left;
    const y = e.clientY - mainSelectorRect.top;
    const elementRect = e?.target?.getBoundingClientRect();
    const elementWidth = elementRect.width;
    const elementHeight = elementRect.height;

    // Calculate the left and top positions within the borders
    let left = ((x - elementWidth / 2) / mainSelectorRect.width) * 100;
    let top = ((y - elementHeight / 2) / mainSelectorRect.height) * 100;

    // Ensure the video stays within the borders
    if (left < 0) {
        left = 0;
    } else if (left > 100 - (elementWidth / mainSelectorRect.width) * 100) {
        left = 100 - (elementWidth / mainSelectorRect.width) * 100;
    }

    if (top < 0) {
        top = 0;
    } else if (top > 91 - (elementHeight / mainSelectorRect.height) * 100) {
        top = 91 - (elementHeight / mainSelectorRect.height) * 100;
    }

    handleChangePosition(left, top, index);
};


  const handleChangePosition = (left, top, index) => {
    if (left === null || top === null) {
      console.log('Both left and top are null.');
      return;
    }

    const updatedBody = [...body];
    const selectedElement = updatedBody[index];

    if (selectedElement && selectedElement.video) {
      const updatedElement = {
        ...selectedElement,
        video:
        {
          ...selectedElement.video,
          style: {
            ...selectedElement.video.style,
            position: 'absolute',
            left: `${roundNumber(left)}%`,
            top: `${roundNumber(top)}%`,
          },
        },

      };



      updatedBody[index] = updatedElement;
      const updatedPageData = {
        ...pageData,
        body: updatedBody,
      };

      setUpdatedPageData(updatedPageData);
    } else {
      console.log(`Element at index ${index} does not exist.`);
    }
  };

  function roundNumber(number) {
    let roundedNumber = Math.round(number * 100) / 100;
    return roundedNumber;
  }

  return (

    <div
      id={`outerVIDEO-${index1}`}
      key={`outerVIDEO-${index1}`}
      {...(contenteditable
        ? {
          draggable: true,
          onDragEnd: (e) => videoDrop(e, index1),
          onFocus: () => {
            setIsListElementSelected(false);
            setSelectedElement({ index1, index2: null });
            setIsElementSelected(true);
          },
        }
        : {})}
      style={{
        left: element.video.style?.left,
        top: element.video.style?.top,
        width: slideSize === "mini" ? (`${element.video.miniVIDEO?.width ? element.video.miniVIDEO.width : 30}px`) : `${size.width}px`,
        height: slideSize === "mini" ? (`${element.video.miniVIDEO?.height ? element.video.miniVIDEO.height : 30}px`) : `${size.height}px`,
        position: "absolute",
        display: "inline-block",
      }}
    >
      {contenteditable && isElementSelected && selectedElement.index1 === index1 && (
        <MediaMenu
          fileType="video"
          handleAddSrc={handleAddSrc}
          setIsElementSelected={setIsElementSelected}
          PIndex={index1}
          handleSubmitGallery={handleSubmitGallery}
          handleDeleteElement={handleDeleteElement}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
        />
      )}
      <div className={contenteditable ? "resizable-media" : null} style={{ width: '100%', height: '100%' }}>
        <video alt={alt} width={'100%'} height={'100%'} autoPlay controls="play pause">
          <source src={elementSrc} type="video/mp4" />
        </video>
        {contenteditable && <div className="resize-handle" onMouseDown={handleMouseDown} />}
      </div>
    </div>
  );
};

export default Video;

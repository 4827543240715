import React from 'react'


function home() {
    return (
        <div style={{border:"1px solid black" , height : "80VH" ,padding: "2%" , margin:"1%"}}>
           <h1>אזור ניהול המערכת</h1> 
           <h2>בדף זה תוכלו להפעיל הדרכה, להוסיף/לעדכן/להסיר משתמשים</h2>
           <h2>להפיק דוחות ועוד...</h2>
        </div>
    )
}

export default home

import React from 'react';

const SuccessMessageBox = (props) => {
    const {setStatus} = props;
 const  style = {
    textAlign: 'center',
    fontSize: '1.5rem',
    color: 'black',
    width: '35rem',
    height: '13rem',
    marginTop: '2%',
    border: '1px solid black',
    backgroundColor: 'lightgreen',
    borderRadius: '5px',
  };
  return (
  <div style={style}>
    <h2>החברה נוספה בהצלחה</h2>
    <button onClick={()=>(setStatus(''))}>OK</button>
  </div>
  );
};

export default SuccessMessageBox;

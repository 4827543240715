import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import StylingMenuForList from './StylingMenuForList';
import { addAnswerToArchive } from '../../../store/slices/tutorialSlice';
import './Quiz.css'; // Import the CSS file

const Quiz = (props) => {
  const dispatch = useDispatch();
  const {
    pageData,
    pageBody,
    setAllowNextPage,
    QuizBody,
    correctAnswer,
    setAnswer,
    PIndex,
    contenteditable,
    setUpdatedPageData,
    selectedElement,
    setSelectedElement,
    setIsListElementSelected,
    setIsElementSelected,
  } = props;

  const { Question, items } = QuizBody;
  const { number, text } = correctAnswer;
  const correctAnswerText = text;
  const correctAnswerNumber = number;

  const callOnce = useRef(false);
  callOnce.current = false;
  const renderAfterCalled = useRef(false);
  const [sendAnswer, setSendAnswer] = useState({});
  const [selectedLabel, setSelectedLabel] = useState(null);

  useEffect(() => {
    setAllowNextPage(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function isEmpty(object) {
      for (const property in object) {
        return false;
      }
      return true;
    }

    if (callOnce.current) return;

    if (!isEmpty(sendAnswer)) {
      dispatch(addAnswerToArchive(sendAnswer));
    }
    callOnce.current = true;
    return () => {
      // eslint-disable-next-line
      if (!renderAfterCalled.current) {
        return;
      }
    };
    // eslint-disable-next-line
  }, [sendAnswer]);

  const handleInputChange = (
    e,
    inputType,
    QuizBody,
    index,
    PIndex,
    pageData,
    updatedBody,
    updatedQuizBody
  ) => {
    const { value } = e.target;
    switch (inputType) {
      case 'NewAnswer':
        if (e.key === 'Enter') {
          e.preventDefault();
          const updatedItems = [
            ...QuizBody.items,
            { text: 'הוסף תשובה אפשרית', style: {} },
          ];
          updatedQuizBody = { ...QuizBody, items: updatedItems };
          updatedBody[PIndex] = {
            ...updatedBody[PIndex],
            body: updatedQuizBody,
          };
          break;
        } else {
          const updatedItems = [...QuizBody.items];
          updatedItems[index] = { text: value, style: {} };
          updatedQuizBody = { ...QuizBody, items: updatedItems };
          updatedBody[PIndex] = {
            ...updatedBody[PIndex],
            body: updatedQuizBody,
          };
          break;
        }
      case 'NewQuestion':
        const updatedQuestion = value;
        updatedQuizBody = { ...QuizBody, Question: updatedQuestion };
        updatedBody[PIndex] = { ...updatedBody[PIndex], body: updatedQuizBody };
        break;
      case 'correctAnswerNumber':
        const updatedCorrectAnswer = { ...pageData.body[PIndex].correctAnswer };
        updatedCorrectAnswer.number = value;
        updatedBody[PIndex] = {
          ...updatedBody[PIndex],
          correctAnswer: updatedCorrectAnswer,
        };
        break;
      case 'correctAnswerText':
        const updatedCorrectAnswer1 = {
          ...pageData.body[PIndex].correctAnswer,
        };
        updatedCorrectAnswer1.text = value;
        updatedBody[PIndex] = {
          ...updatedBody[PIndex],
          correctAnswer: updatedCorrectAnswer1,
        };
        break;
      default:
        break;
    }
    const updatedPageData = { ...pageData, body: updatedBody };
    setUpdatedPageData(updatedPageData);
  };

  const handleChange = (e) => {
    let { value } = e.target;
    value = parseInt(value);
    if ((value + 1).toString() === correctAnswerNumber) {
      setAnswer({ visible: true, text: correctAnswerText, correct: true });
      setSendAnswer({
        Question: Question,
        selected: (value + 1).toString(),
        text: items[value].text,
        correct: true,
      });
      setAllowNextPage(true);
    } else {
      setAnswer({ visible: true, text: correctAnswerText, correct: false });
      setSendAnswer({
        Question: Question,
        selected: (value + 1).toString(),
        text: items[value].text,
        correct: false,
        theCorrectAnswer: correctAnswerNumber,
        theCorrectAnswerText: items[correctAnswerNumber - 1].text,
      });
      setAllowNextPage(false);
    }
  };

  return (
    <div className="quiz-container">
      <h2>{Question}</h2>
      <h3>בחר וסמן את התשובה הנכונה לדעתך</h3>
      <ul className="answer-list">
        {items.map((answer, index) => {
          const labelStyle = {
            color:
              selectedLabel === index
                ? (index + 1).toString() === correctAnswerNumber
                  ? 'green'
                  : 'red'
                : 'black',
            textDecoration:
              selectedLabel === index ? ' underline overline #FF3028' : null,
          };

          return (
            <li
              key={`Q ${PIndex}-${index}`}
              id={`quiz-li-${index}`}
              tabIndex={0}
              onFocus={() => {
                console.log(`Focused element: ${PIndex}-${index}`);
                setSelectedElement({ index1: PIndex, index2: index });
                setIsElementSelected(true);
              }}
            >
              {contenteditable && (
                <>
                  <StylingMenuForList
                    body={pageBody}
                    pageData={pageData}
                    setUpdatedPageData={setUpdatedPageData}
                    selectedElement={selectedElement}
                    setIsElementSelected={setIsElementSelected}
                    setSelectedElement={setSelectedElement}
                    setIsListElementSelected={setIsListElementSelected}
                    elementId={`quiz-li-${index}`}
                    elementType={'quiz'}
                  />
                  <input
                    type="text"
                    value={answer.text}
                    onInput={(e) =>
                      handleInputChange(
                        e,
                        'NewAnswer',
                        QuizBody,
                        index,
                        PIndex,
                        pageData,
                        [...pageBody],
                        { ...QuizBody }
                      )
                    }
                    onKeyDown={(e) =>
                      handleInputChange(
                        e,
                        'NewAnswer',
                        QuizBody,
                        index,
                        PIndex,
                        pageData,
                        [...pageBody],
                        { ...QuizBody }
                      )
                    }
                    onClick={() => setSelectedLabel(index)}
                    style={{ ...labelStyle, ...answer.style }}
                  />
                </>
              )}
              {!contenteditable && (
                <label
                  onClick={() => setSelectedLabel(index)}
                  style={{ ...labelStyle, ...answer.style }}
                >
                  <input
                    type="radio"
                    name="answer"
                    value={index}
                    onChange={handleChange}
                  />
                  {answer.text}
                </label>
              )}
            </li>
          );
        })}
      </ul>
      {contenteditable && (
        <div className="edit-container">
          <h3>רשום את פרטי השאלה:</h3>
          <input
            className="quiz-input"
            type="text"
            value={Question}
            onInput={(e) =>
              handleInputChange(
                e,
                'NewQuestion',
                QuizBody,
                null,
                PIndex,
                pageData,
                [...pageBody],
                { ...QuizBody }
              )
            }
          />
          <div>
            <h3>רשום את מספר התשובה הנכונה ואת התשובה הנכונה:</h3>
            <div className="correct-answer-container">
              <h4>מס' תשובה:</h4>
              <input
                className="quiz-input"
                type="text"
                value={correctAnswerNumber}
                onInput={(e) =>
                  handleInputChange(
                    e,
                    'correctAnswerNumber',
                    QuizBody,
                    null,
                    PIndex,
                    pageData,
                    [...pageBody],
                    {
                      ...QuizBody,
                    }
                  )
                }
              />
            </div>
            <div className="correct-answer-container">
              <h4>התשובה הנכונה:</h4>
              <input
                className="quiz-input"
                type="text"
                value={correctAnswerText}
                onInput={(e) =>
                  handleInputChange(
                    e,
                    'correctAnswerText',
                    QuizBody,
                    null,
                    PIndex,
                    pageData,
                    [...pageBody],
                    {
                      ...QuizBody,
                    }
                  )
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Quiz;

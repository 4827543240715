import { Link } from "react-router-dom";

const RouterLinkImgBlock = ({element,setCurrentPage, specificInternship, setSpecificInternship, setAllowNextPage, router}) => {

  const loadImage = require.context(`../../assetes/images/`, true);

  const search = (key, inputArray) => {  
    for (let i = 0; i < inputArray.length; i++) {
      if (inputArray[i].internshipName === key) {
        return i;
      }
    }
  };

  const handelNextPage = (alt, to) => {   
    const found = search(alt, specificInternship);   
    specificInternship[found].isDone = true;
    setSpecificInternship(specificInternship);   
    setCurrentPage(to);
  };
  
  const findIfToBlur = (alt) => {
   const found =  search(alt, specificInternship)
   if (found === undefined) {return '0.3rem'} else {
      if (specificInternship[found] !== undefined) return specificInternship[found].isDone === true? '0.3rem' : "0rem" };
  };

 
  return (
    <div style={element.style}>
      {element.imgs.map((singleImg, index) => {
        const { to, alt, motion, height, width, isLocal, src, file } = singleImg;
        const localImg = isLocal ? loadImage(file) : "";
        const toBlur = findIfToBlur(alt);
        if (motion !== undefined) {
          return (
            <Link
              key={index}
              to={"/users/page" + to.toString()}  //http://localhost:3000/users/page8
              onClick={(alt) => (specificInternship[specificInternship.find(alt)].isDone = true)
              }
            >
              <motion.img
                animate={motion[0].animate}
                transition={motion[0].transition}
                style={{
                  filter: `grayscale(${0}) blur(${toBlur})`,
                  margin: "0.3rem",
                }}
                alt={alt}
                height={height}
                width={width}
                src={isLocal ? localImg : src}
                key={index}
                onClick={() => setCurrentPage(to)}
              />
              {
                <p
                  style={{
                    filter: `grayscale(${0})  blur(${toBlur})`,
                    margin: "0.3rem",
                  }}
                >
                  {alt}
                </p>
              }
            </Link>
          );
        } else {
          return (
            <Link to={"/users/page" + to.toString()} key={index}  onClick={() => {
              handelNextPage(alt, to);
            }}>
              <img
                style={{
                  filter: `grayscale(${0}) blur(${toBlur})`,
                  margin: "0.3rem",
                }}
                alt={alt}
                height={height}
                width={width}
                src={isLocal ? localImg : src}
                key={index}
              /*   onClick={() => {
                  handelNextPage(alt, to);
                }} */
              />
              {
                <p
                  style={{
                    filter: `grayscale(${0})  blur(${toBlur})`,
                    margin: "0.3rem",
                  }}
                /*   onClick={() => {
                    handelNextPage(alt, to);
                  }} */
                >
                  {alt}
                </p>
              }
            </Link>
          );
        }
      })}
    </div>
  );
};

export default RouterLinkImgBlock;

//FILE NAME: UsersView.jsx
import { useEffect } from "react";
import {axiosPrivate} from "../../../../apis/axiosPrivate";
import "./UsersView.css";
import { useDispatch, useSelector } from "react-redux";

import TableLine from "./tableRow/TableLine";

import {
  // setCornetUser,
  // SetUsers,
  // AddUser,
  deleteUsersAsync,
  updateUserAsync,
  // fetchUsersItems,
  fetchUsersByCompany,
} from "../../../../store/slices/usersSlice";
import { setCornetCompany } from "../../../../store/slices/companySlice";

const handleResetPassword = async (email, setNewPassword) => {
  await axiosPrivate
    .put(`/employees/resetPassword/${email}`)
    .then((res) => setNewPassword(res.data.newPassword));
};

const UsersView = () => {
  const dispatch = useDispatch();
  const usersDB = useSelector(({ users }) => users.AllUsers);
 const CornetUser = useSelector(({ users }) => users.CornetUser);

 // if CornetUser is null the use axios to call logout

if (CornetUser === null) {
  axiosPrivate.get('/logout')
}


  useEffect(() => {
    const role =  CornetUser ? CornetUser?.role: axiosPrivate.get('/logout');
    const CornetCompany = CornetUser?.companyName;
    dispatch(setCornetCompany(CornetCompany));
    dispatch(fetchUsersByCompany(CornetCompany, role));
    
  }, [CornetUser,dispatch]);

  // console.log(usersDB);

  const users = usersDB?.map((user, index) => {
    return {
      name: user?.name,
      email: user?.email,
      password: user?.password,
      internships: user?.tutorialsAllowed,  //////////////////////////////////
    };
  });
  



  return (
    <div className="users-block">
      <div className="main-block">
        <h1>משתמשים</h1>
        <div className="users-table">
        <div>
          <div className="users-table-header">
            <p className="users-table-header-cell">שם משתמש</p>
            <p className="users-table-header-cell">אימייל</p>
            <p className="users-table-header-cell">הדרכה והתמחויות</p>
            {/* <th className="users-table-header-cell"> התמחויות בהדרכה</th> */}
            <p className="users-table-header-cell">פעולות</p>
          </div>
        </div>
          <div className="users-table-body">
         
          {users.map((user, index) => (
            <TableLine
              key={index}
              user={user}
              index={index}
              dispatch={dispatch}
              deleteUsersAsync={deleteUsersAsync}
              updateUserAsync={updateUserAsync}
              handleResetPassword={handleResetPassword}
             
            />
          ))}
         <div style={{height:'inherit'}}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersView;

const DefaultData = {
  0: {
    header: { h1: ["ברוך הבא!"] },
    chapter: "מבוא",
    pageStyle:{padding: '1%',
    width: '97%',
    height: '95%',
    margin: 'auto',
    // display: 'grid',
    // placeSelf: 'center'
  },
    body: [{ type: "h1",style:{display:"grid",placeItems: "center"}, text: ["לומדה חדשה"] }],
  },
};
export default DefaultData;



import {Outlet } from "react-router-dom";
import TopMenu from "../components/general/topMenu/TopMenu";



const menuItems = [ 
  {
    nameHe: "דף הבית",
    nameEn: "Home",
    path: "/admin/home",
    sub: "false",
  },
  {
    nameHe: "לקוחות",
    nameEn: "costumers",
    path: "/admin/Costumers",
    sub: "false",
  },
  {
    nameHe: "הוסף לקוח חדש",
    nameEn: "NewCostumer",
    path: "/admin/AddCostumer",
    sub: "true",
  },
  
  {
    nameHe: "עורך התבניות",
    nameEn: "Add Tamplets",
    path: "/admin/addTampletsByText",
    sub: "true",
  },
];

export default function AdminHome({isInTemplateEditor, setIsInTemplateEditor}) {
  
  return (
  
     <TopMenu list typography = {'תפריט ניהול'} menuItems={menuItems} isInTemplateEditor={isInTemplateEditor} setIsInTemplateEditor={setIsInTemplateEditor}>
       <Outlet />
     </TopMenu>
         
       
     
   
  );
}

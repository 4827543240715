import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { useState} from 'react';
// import {setNewCompany} from "../../routesControlers/routesControlers"
// import { InfoMessageBox, ErrorMessageBox, WarningMessageBox, SuccessMessageBox } from "@fremtind/jkl-message-box-react";
// import "@fremtind/jkl-message-box/message-box.min.css";
import SuccessMessageBox from './components/SuccessMessageBox';
// import axios from '../../apis/axios';
import {axiosPrivate} from '../../apis/axiosPrivate';
// import {useEffect} from 'react';
// eslint-disable-next-line
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line
import { AddCompany } from '../../store/slices/companySlice';

import { TextField } from '@mui/material';
//styles
const styles = {
  login: {
    placeItems: 'center',
    margin: 'auto',
    backgroundColor: 'white',
    // width: "25%",
    minWidth: 600,
    maxWidth: 100,
  },
  h1: { display: 'flex', justifyContent: 'center' },
  h2: { display: 'flex', justifySelf: 'right', margin: '0' },
  form: {
    display: 'grid',
    padding: '2rem',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  div: {
    border: '1px solid black',
    padding: '2rem',
    display: 'grid',
    placeItems: 'center',
    marginTop: '5%',
  },
  FormHelperText: {
    marginTop: '-4.1rem',
    backgroundColor: 'white',
    zIndex: '1',
    width: 'max-content',
  },
};
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
});

function AddNewCostumerPage() {
  const dispatch = useDispatch();
const [status,setStatus] = useState(null);

const setNewCompany = (companyName,logo,color,adminUserEmail,adminUserName,setStatus) => {
  axiosPrivate
    .post('/companies/', { companyName,  companyIcon: logo,  backgroundColor: color,  adminUserEmail, adminUserName})
    .then((res) => {     
      if (res.data.message === 'company added successfully') {      
        setStatus(res.data.message);
        companyName = '';
        logo = '';
        color = '';
        adminUserEmail = '';
        adminUserName = '';
      } else {
        console.log('else: ', res.data.message);
      }
    })
    .catch((err) => console.log(err.message));
};

// useEffect(() => {console.log(status)},[status])

  const handleSubmit = (e) => {
    e.preventDefault();
    const { companyName, logo, color, adminUserEmail, adminUserName } = e.target;
     
    dispatch(
      AddCompany({
        companyName: companyName.value,
        companyIcon: logo?.value,
        backgroundColor: color.value,
      })
    );
  
  setNewCompany(companyName.value,logo.value,color.value,adminUserEmail.value,adminUserName.value,setStatus);
  };
  return (
    <CacheProvider value={cacheRtl}>
      <div style={{ display: 'grid', width: '100%', height: '90%' }}>
        <div style={styles.login}>
      {status === "company added successfully"? <SuccessMessageBox setStatus={setStatus}> </SuccessMessageBox>: //null}
          <div>
          <div style={styles.header}>
            <div style={{ width: '90%' }}>
              <h1 style={styles.h1}>פרטי חברה</h1>
            </div>
            {/* <img src={logo} style={{ width: "30%" }} alt="logo" /> */}
          </div>
          <form style={styles.form} onSubmit={handleSubmit}>
            <TextField
              id="companyName"
              fullWidth
              sx={{ height: '3rem', marginTop: '0' }}
              // autoComplete="username"
              type="text"
              label="שם חברה"
              // helperText={}
            />
            <TextField
              // dir="RTL"
              id="logo"
              fullWidth
              sx={{ height: '3rem', marginTop: '2.5rem' }}
              // autoComplete="username"
              type="url"
              label="קישור ללוגו חברה"
              // helperText={'לוגו חברה'}
              // FormHelperTextProps={{
              //   style: styles.FormHelperText,
              // }}
            />
            <TextField
              id="color"
              fullWidth
              sx={{ height: '3rem', marginTop: '2.5rem' }}
              // autoComplete="username"
              type="text"
              defaultValue="white"
              label="צבע רקע של הלוגו"
              // helperText={}
            />

            <div style={styles.div}>
              <h2 style={styles.h2}>משתמש בררת מחדל</h2>
              <TextField
                id="adminUserEmail"
                fullWidth
                sx={{ height: '3rem', marginTop: '1rem' }}
                // autoComplete="username"
                type="text"
                label="מייל של משתמש לניהול המערכת (חובה)"
                // helperText={}
              />
              <TextField
                id="adminUserName"
                fullWidth
                sx={{ height: '3rem', marginTop: '1rem' }}
                // autoComplete="username"
                type="text"
                label="שם המשתמש (חובה)"
                // helperText={}
              />
            </div>
            <input
              style={{ marginTop: '2%', justifySelf: 'end', width: '5rem' }}
              type="submit"
              value="OK"
            />
          </form>
          </div>}
        </div>
      </div>
    </CacheProvider>
  );
}

export default AddNewCostumerPage;

import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios from '../../apis/axios';
import {axiosPrivate} from '../../apis/axiosPrivate';

export const fetchInternshipItems = createAsyncThunk(
  "internship/fetchInternshipItems",
  async () => {
    const response = await axiosPrivate.get("/internships/");
    return response.data;
  }
);

export const deleteInternshipAsync = createAsyncThunk(
  "internship/deleteInternship",
  async (internship) => {
    try {
      await axiosPrivate.delete(`/internships/${internship}`);
    } catch (error) {
      console.error(error);
    }
  }
);

export const addInternshipAsync = createAsyncThunk(
  "internship/addInternship",
  async (internship) => {
    try {
      await axiosPrivate.post(`/internships/`, internship);
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateInternshipAsync = createAsyncThunk(
  "internship/updateInternship",
  async (internship) => {
    try {
      await axiosPrivate.put(`/internships/${internship.id}`, internship);
    } catch (error) {
      console.error(error);
    }
  }
);



/* 
export function fetchInternshipItems() {
    return async (dispatch) => {
      axiosPrivate
        .get('/internships/')
        .then((response) => {
          dispatch(SetInternships(response.data));
        })
        .catch((er) => {
          console.error(er);
        });
    };
  }

  export function deleteInternshipAsync(internship) {  
    return async (dispatch) => {
      try {
        await axiosPrivate.delete(`/internships/${internship}`)
          .then(() => dispatch(DeleteInternship(internship)));
      } catch (error) {
        console.error(error);
      }
    };
  }
  export function addInternshipAsync(internship) {
    return async (dispatch) => {
      try {
        await axiosPrivate.post(`/internships/`, internship)
          .then((res) => {         
            if (res.status === 200) {
              return (dispatch(AddInternship(internship)))
               }
              });
  
          
      } catch (error) {
        console.error(error);
      }
    };
  }
*/
const InternshipSlice = createSlice({
    name: "Internship",
    initialState: {        
        internships: [],       
    },
    reducers: {      
        AddInternship: (state, { payload }) => {
            state.internships.push(payload);
        },
        SetInternships: (state, { payload }) => {
            state.internships = payload;
          },
      
        DeleteInternship: (state, { payload }) => {
            state.internships = state.internships.filter((Internship) => Internship.name !== payload);
        },
        
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchInternshipItems.fulfilled, (state, action) => {
          state.internships = action.payload;
        })
        .addCase(fetchInternshipItems.rejected, (state, action) => {
          console.log(action.error.message);
        })
        .addCase(fetchInternshipItems.pending, (state, action) => {
          console.log("pending");
        })
        .addCase(deleteInternshipAsync.fulfilled, (state, action) => {
          state.internships = state.internships.filter(
            (internship) => internship.id !== action.payload
          );
        })
        .addCase(deleteInternshipAsync.rejected, (state, action) => {
          console.log(action.error.message);
        })
        .addCase(deleteInternshipAsync.pending, (state, action) => {
          console.log("pending");
        })
        .addCase(addInternshipAsync.fulfilled, (state, action) => {
          state.internships.push(action.payload);
        })
        .addCase(addInternshipAsync.rejected, (state, action) => {
          console.log(action.error.message);
        })
        .addCase(addInternshipAsync.pending, (state, action) => {
          console.log("pending");
        })
        .addCase(updateInternshipAsync.rejected, (state, action) => {
          console.log(action.error.message);
        })
        .addCase(updateInternshipAsync.pending, (state, action) => {
          console.log("pending");
        })
        .addCase(updateInternshipAsync.fulfilled, (state, action) => {
          state.internships = state.internships.map((internship) =>
            internship.id === action.payload.id ? action.payload : internship
          );
        });
    },
    

});



export const { AddInternship,DeleteInternship,SetInternships } =
InternshipSlice.actions;

export default InternshipSlice.reducer;
import { Outlet } from "react-router-dom";
import {  useSelector } from "react-redux";
import TopMenu from "../components/general/topMenu/TopMenu";

const menuItems = [
  { nameHe: "דף הבית", nameEn: "Home", path: "/user-admin/home/" },
  {
    nameHe: "משתמשים (הוספה/הסרה/עריכה)",
    nameEn: "company Users",
    path: "/user-admin/editUsers/",
  },
  {
    nameHe: "הפעלת הדרכה",
    nameEn: "Activate Tutorial",
    path: "/user-admin/activate-tutorial/",
  },
  {
    nameHe: "הפקת דוחות ביצוע",
    nameEn: "Reports",
    path: "/user-admin/Reports/",
  },
];



export default function AdminHome() {

const isInTemplateEditor= false;
  const CornetCompany = useSelector(({ companies }) => companies.CornetCompany);
  return (
    
    <TopMenu list typography = {`תפריט ניהול של חברת ${CornetCompany}`} menuItems={menuItems} isInTemplateEditor={isInTemplateEditor} >
    <Outlet />
  </TopMenu>
   
  );
}

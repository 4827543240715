import React from "react";
import "./OnOffSwitch.css";

const OnOffSwitch = ({state,onClick}) => {
//   // state={status} setIsActive={setIsActive} onClick={handleOnClick}
//    console.log(state)
//   const [selected, toggleSelected ] = React.useState(state==="not_active" ? false : state==="active" ? true : false);
//   const handleChange = () => {    
//     toggleSelected(!selected);
//     setIsActive(selected===false?"not_active":"active");
//     onClick();
//   };
//  React.useEffect(() => {   
//     toggleSelected(state==="not_active" ? false : state==="active" ? true : false);
//     }, [state]);
console.log(state);
  return (
   
      <div className="toggle-container" onClick={onClick}>
        <div className={`dialog-button ${state === 'active' ? "" : "disabled"}`}>
          {state === 'active' ? "ON" : "OFF"}
        </div>
      </div>

      /* 
      <div className="toggle-container" onClick={onClick}>
        <div className={`dialog-button ${state === 'active'? "" : "disabled"}`}>
          {state ? "ON" : "OFF"}
        </div>
      </div>
      */
   
  );
};

export default OnOffSwitch;

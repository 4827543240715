
import MeneButton from './MenuButton';
import { useSelector,useDispatch } from 'react-redux';
import { setTutorial ,setContentPage} from "../../../../store/slices/tutorialSlice";
import p from './images/p.png';
import h1 from './images/h1.png';
import h2 from './images/h2.png';
import h3 from './images/h3.png';
import img from './images/img.jpeg';
import oList from './images/oList.png';
import uoList from './images/uoList.png';
import link from './images/link.png';
import quiz from './images/quiz.png';
import video from './images/video.png';

const MenuContol = (props) => {
  const dispatch = useDispatch();
 const {showText,showTitle} = props;
  const { contentPage,tutorialSlides,slideNumber } = useSelector((state) => state.tutorial);
// console.log('base: ',contentPage)



const addContent = (content) => {

const newBody =  [...contentPage.body,content]
const newBodyContent = {...contentPage,body:newBody};
  //  console.log('addContent:',newBodyContent)
   
   dispatch(setContentPage(newBodyContent));

      const newSlides = tutorialSlides.map((slide, index) =>
        index === slideNumber ? newBodyContent : slide
      );
    
      dispatch(setTutorial(newSlides));
  };
  const saveContent = (content) => {
    const newBody =  [...contentPage.body,content]  
    const newBodyContent = {...contentPage,body:newBody};
    //  console.log('addContent:',newBodyContent)

    dispatch(setContentPage(newBodyContent));
    const newSlides = tutorialSlides.map((slide, index) =>
      index === slideNumber ? newBodyContent : slide
    );

    dispatch(setTutorial(newSlides));
  };


  // console.log(addContent({ type: "h2", text: [] }))
  const menuButtons = [
    // { src: h1, alt: 'כותרת 1', onClick: addContent({ type: "h1", text: [] }) },
    { src: h1, alt: 'כותרת 1', toAdd: {type: "h1", text: ['רשום כותרת']} },
    { src: h2, alt: 'כותרת 2', toAdd: { type: "h2", text: ['רשום כותרת'] }  },   
    { src: h3, alt: 'כותרת 3', toAdd: { type: 'h3', text: ['רשום כותרת'] } },
    { src: h3, alt: 'כותרת 4', toAdd: { type: 'h4', text: ['רשום כותרת'] } },
    { src: p, alt: 'טקסט', toAdd: { type: 'p', text: ['רשום טקסט'] } },    
    { src: oList, alt: 'רשימה 1', toAdd: { type: 'oList', items: [{text:'טקסט1',style:{}},{text:'טקסט2',style:{}}] } },
    { src: uoList, alt: 'רשימה 2', toAdd: { type: 'uoList', text: ['טקסט1','טקסט2'] } },
   
    { src: img, alt: 'סט תתמונות', toAdd:{
      type: 'imgBlock',
      imgs: [
        {
          alt: 'תאור תמונה אוטומטי',
          width: '500',
          height: '256',
          style: {
            position: 'absolute',
            left: '0%',
            top: '0%'
          },
          src: '',
          miniIMG: {           
            width: 50,
            height: 50
          }
        },
      ],
    } },
    { src: video, alt: 'וידאו', toAdd:{
      type: 'video',
      video: 
        {
          alt: 'תאור וידאו אוטומטי',
          width: '500',
          height: '256',
          style: {
            position: 'absolute',
            left: '0%',
            top: '0%'
          },
          miniVIDEO: {          
            width: 50,
            height: 50
          },
          src: '',
        },      
    } },
    { src: link, alt: 'לינק', toAdd: { type: "link", src: [""], text: "לינק" } },
    { src: quiz, alt: 'שאלה', toAdd: {
      type: 'quiz',
      body: { Question: 'רשום שאלה', items: [{text:'תשובה1',style:{}},{text:'תשובה2',style:{}}] },
      correctAnswer: { number: '1', text: 'טקסט התשובה הנכונה' },
    } },
  ];

 
  return (
    <div className={showText?"right-box-menu":"right-box-menu-no-text"}>
      {menuButtons.map((buttonSettings, index) => (
        <MeneButton key={index} buttonSettings={buttonSettings} onClick={menuButtons.src==="save"?saveContent:addContent} showText={showText} showTitle={showTitle}/>
      ))}
    </div>
  );
};

export default MenuContol;


/* 

{ type: 'h2', text: [''] },
          { type: 'h3', text: [''] },
          { type: 'p', text: [''] },
          {
            type: 'imgBlock',
            imgs: [
              {
                alt: '',
                width: '150rem',
                height: '150rem',
                style: {
                  marginLeft: '10%',
                },
                src: '',
              },
            ],
          },
          { type: 'oList', text: [''] },
          { type: 'uoList', text: [''] },
           { type: "link", src: [""], text: "" },  
          {
            type: 'quiz',
            body: { Question: 'רשום שאלה', answers: ['תשובה 1', 'תשובה 2'] },
            correctAnswer: { number: '1', text: 'טקסט התשובה הנכונה' },
          },
*/
import { useEffect, useState } from 'react';
import LiElement from './LiElement';
import StylingMenu from '../StylingMenu';
const List = ({
  items,
  type,
  dir,
  contenteditable,
  handleInput,
  isSelected,
  onBlur,
  style,
  onFocus,
  selectedElement,
  setSelectedElement,
  dragStarted,
  dragOver,
  body,
  pageData,
  setUpdatedPageData,
  index1,
  setIsListElementSelected,
  setIsElementSelected,
  dragDroppedInArray,
  dragStartedInsideList,
  dragOverInsideList,
  draggedOverIndexInsideList,
  draggedStartedListIndex,
}) => {
  const [selectedListElement, setSelectedListElement] = useState(items);

  useEffect(() => {   
    if (items == undefined) return setSelectedListElement([]);
    setSelectedListElement(items);  
  }, [items]);

  

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      const updatedBody = [...body];
      const updatedListObject = { ...updatedBody[index1] }; // Copy the object
      const newItem = {
        text: `טקסט${updatedListObject.items.length + 1}`,
        style: {}
      };
      
      updatedListObject.items = [
        ...updatedListObject.items,
        newItem
      ]; // Update the items array
  
      // Replace the old list object with the updated one
      updatedBody[index1] = updatedListObject;
  
      setUpdatedPageData({ ...pageData, body: updatedBody });
  
      // Make sure to update selectedListElement as well
       setSelectedListElement(updatedListObject?.items);
    }
  };
  

  const ListType = type;
  return (
    <>
      
      <ListType
        draggable={contenteditable} // Add the draggable attribute here
        onDragStart={(e) => dragStarted(e, index1)} // Handle the drag start event here
        onDragOver={(e) => dragOver(e, index1)}
        onFocus={() => {
          // setSelectedElement({ index1, index2: null });
          setIsElementSelected(true);
        }}
        id={`list-${index1}`}
        className={isSelected ? 'selected' : null}
      >
        {        
        selectedListElement?.map((item, index) => (
          <LiElement
            key={index}
            index1={index1}
            index={index} // selected element insid the list
            item={item.text}
            body={body}
            pageBody={body}
            pageData={pageData}
            setUpdatedPageData={setUpdatedPageData}
            dir={dir}
            type={type}
            contenteditable={contenteditable}
            handleInput={handleInput}
            isSelected={isSelected}
            onBlur={onBlur}
            style={{ ...style, ...item.style }}
            onFocus={onFocus}
            handleKeyDown={handleKeyDown}
            setSelectedElement={setSelectedElement}
            selectedElement={selectedElement}
            dragStartedInsideList={dragStartedInsideList}
            dragOverInsideList={dragOverInsideList}
            draggedOverIndexInsideList={draggedOverIndexInsideList}
            draggedStartedListIndex={draggedStartedListIndex}
            setIsElementSelected={setIsElementSelected}
            setIsListElementSelected={setIsListElementSelected}
            dragDroppedInArray={dragDroppedInArray}

          />
        ))
        }
      </ListType>
     
    </>
  );
};

export default List;

import Home from "./home";
import Error from "../error/Error";
import Users from "./Users";
import UserDataTamplet from "./UserDataTamplet/UserDataTamplet-rc-table";
// import UserDataTamplet from "./UserDataTamplet/UserDataTamplet-rsuite-table";
// import TutorialsTamale from "../../pages/tutorials";
import { Routes, Route } from "react-router-dom";



const RoutesUserAdmin = () => {
  return (
    <Routes>    
    <Route  path="/home"element={<Home />}/>
    <Route  path="/editUsers" element={ <Users />} />
    {/* <Route  path="/activate-tutorial"element={ <TutorialsTamale/>}/> select tutorial from list and activate it */}
    <Route  path="/Reports/"element={<UserDataTamplet /> }/> 
    
    <Route path="/*" element={<Error />} />
  </Routes>
  )
}

export default RoutesUserAdmin
import shortMode from './mode'; // Import mode.js file
// let shortMode = 1; // 'dev = 0' or 'prod = 1'
let modeArray = ['development', 'production'];
let mode = null;
let localPort = null;
let localHost = null;
let remoteHost = null;

if (shortMode === 0) { // Compare with numeric 0
  localPort = 4000; // Local port number
  localHost = `http://localhost:${localPort}/`; // Local host name
  mode = modeArray[shortMode];
} else {
  mode = modeArray[shortMode];
  remoteHost = `https://myway.biznagish.co.il/`; // Remote host name
}

export const settingData = {
  localHost,
  remoteHost,
  mode,
};

//UserFormControl.jsx
import SmartSelect from "react-smart-select";
import BoxAria from "./BoxAria";
import React from "react";

/* name, email, role, password,internships, companyName */
export function UserFormControl(props) {
 const { newUserDistils, setNewUserDistils } = props;
  // setNewUserDistils
  return (<div className="user-form-control">
    <form style={{
      alignSelf: "baseline"
    }} onSubmit={props.handleSubmitUser}>
      <div className="user-form-control">
        <div>
          <label style={props.labelStyle}>שם מלא</label>
          <input id="Name" className="user-input" type="text" placeholder="Name" onChange={(e)=>setNewUserDistils({...newUserDistils,name : e.target.value})}/>
        </div>
        <div>
          <label style={props.labelStyle}>אימייל</label>
          <input id="myEmail" className="user-input" type="text" placeholder="Email" onChange={(e)=>setNewUserDistils(()=>({...newUserDistils,email : e.target.value}))}/>
        </div>
        <div>
          <label style={props.labelStyle}>סיסמה</label>
          <input id="Password" className="user-input" type="password" placeholder="Password"  onChange={(e)=>setNewUserDistils(()=>({...newUserDistils,password : e.target.value}))}/>
        </div>

        <div>
          <label style={props.labelStyle}>חברה</label>
          <button className="user-button" type="submit">
            הוסף משתמש
          </button>
        </div>
      </div>
    </form>
    <form onSubmit={e => props.handleSubmit(props.addTutorialToUser(e))}>
      <div className="user-form-control">
        <div className="dropdown">
          <label className="dropdown-label" style={props.labelStyle}>
            הדרכות רצויות
          </label>

          <SmartSelect value={props.selectTutorialValue} options={props.selectTutorialOptions} style={props.style} optionStyle={props.optionStyle} dropdownStyle={props.dropdownStyle} onChange={e => {
            props.handleSelectTutorial(e);
            return props.setSelectTutorialValue(e);
          }} labelStyle={props.labelStyle} />
        </div>
        <div className="dropdown">
          <label className="dropdown-label" style={props.labelStyle}>
            התמחויות רצויות
          </label>
          <SmartSelect value={props.selectInternshipValue} options={props.selectInternshipOptions} style={props.style} optionStyle={props.optionStyle} dropdownStyle={props.dropdownStyle} onChange={props.setSelectInternshipValue} multi toggle labelStyle={props.labelStyle} />
        </div>
        <input type="submit" style={{
          display: "flex",
          alignSelf: "end",
          marginBottom: "2%"
        }} className="user-input" value="הוסף הדרכה" />
      </div>
      <BoxAria numOfTutorials={props.numOfTutorials} selectedUserTutorials={props.selectedUserTutorials} />
    </form>
  </div>);
}

 import axios from 'axios';
 import { settingData } from '../config/settings';
 const { remoteHost, localHost, mode } = settingData;
 const BASE_URL = mode === 'production' ? remoteHost : localHost;

export default axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json'}
});
export const axiosFileUpload = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: { 'Content-Type': 'multipart/form-data',
    charset:'utf-8'
}
});

export const axiosDownload = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' , 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
});





import { useEffect, useState } from "react";

import { useSelector ,useDispatch } from "react-redux";

import Tutorials from "../tutorials";

import TopMenu from "../components/general/topMenu/TopMenu";
import { setTemplateName ,setTutorial,setInternships,setAnswerArchive,setNumberOfQuestions} from "../../store/slices/tutorialSlice";

const BasicUserPage = () => {
  const [tutorialName, setTutorialName] = useState("");
  const [tutorialToDisplay, setTutorialToDisplay] = useState([]);

  const CornetCompany = useSelector(({ companies }) => companies.CornetCompany);
  
  const dispatch = useDispatch();
  // const TutorialNames = useSelector(
  //   ({ companies }) => companies.TutorialsNamesForCurrentCompany
  // );
  const userTuterials = useSelector(
    ({ users }) => users?.CornetUser?.tutorialsAllowed
  );
  const TutorialNames  = userTuterials.map((tutorial) => tutorial.tutorialName)

  const CornetCompanyData = useSelector(
    ({ companies }) => companies?.CornetCompanyData
  );
  const tutorials = CornetCompanyData?.templates;

  useEffect(() => {
    setTutorialToDisplay(
      tutorials?.find((template) => template.Name === tutorialName)
        ? tutorials?.find((template) => template.Name === tutorialName)
        : []
    );
  }, [tutorialName, tutorials]);

  const handleStartTutorialSubmit = (e) => {
    e.preventDefault();
    setTutorialName(e.target.selectTutorial.value);
    dispatch(setTemplateName(e.target.selectTutorial.value))
    dispatch(setTutorial(tutorialToDisplay.data))
    dispatch(setInternships(tutorialToDisplay.Internships))
    dispatch(setAnswerArchive({theTutorial:e.target.selectTutorial.value,theAnswers:[]}))
    dispatch(setNumberOfQuestions())
  };

  return (
    <TopMenu
      list={false}
      handleStartTutorialSubmit={handleStartTutorialSubmit}
      TutorialNames={TutorialNames}
      typography={` הדרכה עבור חברת ${CornetCompany}`}
    >
      {tutorialName !== "" ? (
        <Tutorials tutorialToDisplay={tutorialToDisplay} />
      ) : (
        <p style={{ display: "grid", placeItems: "center", fontSize: "32px" }}>
          אנא בחר הדרכה
        </p>
      )}
    </TopMenu>
  );
};

export default BasicUserPage;

// import React, { useState } from "react";
import { FcPlus } from "react-icons/fc";
import {  IoMdRemoveCircle } from "react-icons/io";
import { useDispatch } from "react-redux";
import {
  // setTemplateName,
  // setContentPage,
  // setTutorial,
  removePageFromTutorial,
  addPageToTutorial,
} from "../../../store/slices/tutorialSlice";

const AddButton = ({ index }) => {
  const dispatch = useDispatch();

  // console.log(test)
  // const [showAdd, setShowAddButton] = useState(true);
  // const [showRemove, setRemoveButton] = useState(true);
  const handleSowAddSlide = () => {
    // setShowAddButton(true);
  };
  const handleSowRemoveSlide = () => {
    // setRemoveButton(true);
  };

  const handleAddSlide = () => {
    // setShowAddButton(false);
    dispatch(addPageToTutorial(index));
  };
  const handleRemoveSlide = () => {
    // setShowAddButton(false);
    dispatch(removePageFromTutorial(index));
  };

  return (
    <div className="control-slide-box">
      <div className="add-box" onMouseEnter={handleSowAddSlide}>
       
          <div className="add-slide-button">
            <FcPlus
              size={30}
              // onMouseLeave={() => setShowAddButton(false)}
              color="red"
              onClick={handleAddSlide}
              className={"add-slide-button-show" }
            />
          </div>
       
      </div>
      <div className="remove-box" onMouseEnter={handleSowRemoveSlide}>
      
          <div className="remove-slide-button">
            <IoMdRemoveCircle
              size={30}
              // onMouseLeave={() => setRemoveButton(false)}
              color="red"
              fill="red"
              onClick={handleRemoveSlide}
              className={"remove-slide-button-show"  }
            />
          </div>
       
      </div>
    </div>
  );
};

export default AddButton;

//  import React from "react";
 import {  useState } from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import AuthProvider from "./context/AuthProvaider";
// import { createContext } from 'react';
// components

import Login from './pages/login/Login';
import Admin from './pages/admin/AdminMain';
import AdminRouts from './pages/admin/AdminRouts';
import UserAdmin from './pages/userAdmin/userAdminMain';
import RoutesUserAdmin from './pages/userAdmin/RoutesUserAdmin';
// import Users from './pages/tutorials/';
import BasicUserPage from './pages/Costumers/BasicUserPage';

import Error from './pages/error/Error';

function App() {

 
  const [isInTemplateEditor,setIsInTemplateEditor] = useState(false);
  
  return (
    
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      {/* <AuthProvider> */}
     
      <Router>
        <Routes>
          <Route exact path="/" element={<Login/>} />

          <Route exact path="/user-admin" element={<UserAdmin />}>
            <Route path="*" element={<RoutesUserAdmin  />} />
          </Route>

          <Route exact path="/users/*" element={<BasicUserPage />} />

          <Route exact path="/admin/" element={<Admin  isInTemplateEditor ={isInTemplateEditor} setIsInTemplateEditor={setIsInTemplateEditor}/>}>
            <Route path="*" element={<AdminRouts isInTemplateEditor ={isInTemplateEditor} setIsInTemplateEditor={setIsInTemplateEditor}/>} />
          </Route>

          <Route exact path="/Login" element={<Login />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </Router>
    {/* </AuthProvider> */}
        
    </div>
  );
}

export default App;

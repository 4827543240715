import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import MediaMenu from "../MediaMenu";

const ImgBlock = (props) => {
  const {
    element,
    contenteditable,
    index1,
    onClick,
    handleAddSrc,
    setIsElementSelected,
    isElementSelected,
    selectedElement,
    slideSize,
    setIsListElementSelected,
    setSelectedElement,
    pageData,
    setUpdatedPageData,
    body,
    handleDeleteElement
  } = props;

  // const [size, setSize] = useState({ width: 200, height: 200 });
  const [size, setSize] = useState({ width: element?.imgs[0]?.width, height: element?.imgs[0]?.height });
  const [miniIMG, setMiniSize] = useState({ width: 100, height: 100 });

  useEffect(() => {
    const slideSizeOb = document?.querySelector("#page-body")?.getBoundingClientRect();
    const miniSlideSizeOb = document?.querySelector("#mini-page-body")?.getBoundingClientRect();
    handleChangeSize(size.width, size.height, index1);
    if (miniSlideSizeOb) {
    const xRatio = slideSizeOb.width / miniSlideSizeOb.width;
    const yRatio = slideSizeOb.height / miniSlideSizeOb.height;
    const newMiniWidth = size.width / xRatio;
    const newMiniHeight = size.height / yRatio;
    const tempMiniSize = { width: newMiniWidth, height: newMiniHeight };
    setMiniSize(tempMiniSize);
    }
    // eslint-disable-next-line
  }, [size]);

  const handleChangeSize = (width, height, index) => {
    const updatedBody = [...pageData.body];
    const selectedElement = updatedBody[index];

    if (selectedElement && selectedElement.imgs && selectedElement.imgs[0]) {
      const updatedElement = {
        ...selectedElement,
        imgs: [
          {
            ...selectedElement.imgs[0],
            width: width,
            height: height,
            miniIMG: miniIMG,
          },
        ],
      };

      // Update the pageData
      updatedBody[index] = updatedElement;

      const updatedPageData = {
        ...pageData,
        body: updatedBody,
      };

      // Set the Updated PageData
      setUpdatedPageData(updatedPageData);
    } else {
      console.log(`Element at index ${index} does not exist.`);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();

    const initialX = e.clientX - size.width;
    const initialY = e.clientY - size.height;

    const handleMouseMove = (e) => {
      setSize({
        width: e.clientX - initialX,
        height: e.clientY - initialY,
      });
      // updateImagePosition(e.clientX, e.clientY);

    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleSubmitGallery = (index, selectedImageName) => {
    const updatedBody = [...pageData.body];
    const selectedElement = updatedBody[index];

    if (selectedElement && selectedElement.imgs && selectedElement.imgs[0]) {
      const updatedElement = {
        ...selectedElement,
        imgs: [
          {
            ...selectedElement.imgs[0],
            src: selectedImageName,
          },
        ],
      };

      updatedBody[index] = updatedElement;
      const galleryUpdatedPageData = {
        ...pageData,
        body: updatedBody,
      };
      console.log("handleSubmitGallery setUpdatedPageData");
      setUpdatedPageData(galleryUpdatedPageData);
    } else {
      console.log(`Element at index ${index} does not exist or does not have the required structure.`);
    }
  };


  function roundNumber(number) {
    let roundedNumber = Math.round(number * 100) / 100;
    return roundedNumber;
  }


  const handleChangePosition = (left, top, index) => {
    if (left === null || top === null) {
      console.log('Both left and top are null.');
      return;
    }

    const updatedBody = [...body];
    const selectedElement = updatedBody[index];

    if (selectedElement && selectedElement.imgs && selectedElement.imgs[0]) {
      const updatedElement = {
        ...selectedElement,
        imgs: [
          {
            ...selectedElement.imgs[0],
            style: {
              ...selectedElement.imgs[0].editableStyle,
              position: 'absolute',
              left: `${roundNumber(left)}%`,
              top: `${roundNumber(top)}%`,
            },
          },
        ],
      };

      updatedBody[index] = updatedElement;
      const updatedPageData = {
        ...pageData,
        body: updatedBody,
      };
      console.log('handleChangePosition setUpdatedPageData')
      setUpdatedPageData(updatedPageData);
    } else {
      console.log(`Element at index ${index} does not exist.`);
    }
  };
  
/*   const imageDrop = (e, index) => {
    e.preventDefault();
    const mainSelector = document.querySelector(".content-right");
    const mainSelectorRect = mainSelector.getBoundingClientRect();
    const x = e.clientX - mainSelectorRect.left;
    const y = e.clientY - mainSelectorRect.top;
    const elementRect = e.target.getBoundingClientRect();
    const elementWidth = elementRect.width;
    const elementHeight = elementRect.height;
    const left = ((x - elementWidth / 2) / mainSelectorRect.width) * 100;
    const top = ((y - elementHeight / 2) / mainSelectorRect.height) * 100;

    handleChangePosition(left, top, index);
  };
 */
  const imageDrop = (e, index) => {
    e.preventDefault();
    const mainSelector = document.querySelector(".content-right");
    const mainSelectorRect = mainSelector.getBoundingClientRect();
    const x = e.clientX - mainSelectorRect.left;
    const y = e.clientY - mainSelectorRect.top;
    const elementRect = e.target.getBoundingClientRect();
    const elementWidth = elementRect.width;
    const elementHeight = elementRect.height;

    // Calculate the left and top positions within the borders
    let left = ((x - elementWidth / 2) / mainSelectorRect.width) * 100;
    let top = ((y - elementHeight / 2) / mainSelectorRect.height) * 100;

    // Ensure the video stays within the borders
    if (left < 0) {
        left = 0;
    } else if (left > 100 - (elementWidth / mainSelectorRect.width) * 100) {
        left = 100 - (elementWidth / mainSelectorRect.width) * 100;
    }

    if (top < 0) {
        top = 0;
    } else if (top > 100 - (elementHeight / mainSelectorRect.height) * 100) {
        top = 100 - (elementHeight / mainSelectorRect.height) * 100;
    }

    handleChangePosition(left, top, index);
  };


  return (
    <>
      {element.imgs.map((singleImg, index) => {
        var localImg = "";
        if (singleImg.motion !== undefined) {
          if (singleImg.isLocal) {
            const theImages = require.context(`../../assetes/images/`, true);
            localImg = theImages(singleImg.file);
          }

          return (
            <motion.img
              animate={singleImg.motion[0].animate}
              transition={singleImg.motion[0].transition}
              style={singleImg.style ? singleImg.style : { margin: "0.3rem" }}
              alt={singleImg.alt}
              height={singleImg.height}
              width={singleImg.width}
              src={singleImg.isLocal ? localImg : singleImg.src}
              key={index}
              onClick={onClick}
            />
          );
        } else {
          if (singleImg.isLocal) {
            const theImages = require.context(`../../assetes/images/`, true);
            localImg = theImages(singleImg.file);
          }
          return (
            <div
              id={`outerIMG-${index}`}
              key={`outerIMG-${index}`}
              draggable={true}
              onDragEnd={(e) => imageDrop(e, index1)}
              onFocus={() => {
                setIsListElementSelected(false);
                setSelectedElement({ index1, index2: null });
                setIsElementSelected(true);
              }}
       
              style={{             
                left: singleImg?.style?.left,
                top:singleImg?.style?.top,               
                 width:  slideSize === "mini" ? `${singleImg?.miniIMG?.width?singleImg.miniIMG.width:30}px`   : `${size.width}px`,
                height: slideSize === "mini" ? `${singleImg?.miniIMG?.height?singleImg.miniIMG.height:30}px` : `${size.height}px`,
                position: "absolute",
                display: "inline-block",
              }}
             
              
            >
              {contenteditable && isElementSelected && selectedElement.index1 === index1 && (
                <MediaMenu
                  handleAddSrc={handleAddSrc}
                  setIsElementSelected={setIsElementSelected}
                  PIndex={index1}
                  handleSubmitGallery={handleSubmitGallery}
                  handleDeleteElement={handleDeleteElement}
                  selectedElement={selectedElement}
                  setSelectedElement={setSelectedElement}
                />
              )}
              <div className="resizable-media"
              style={{              
                width:  '100%',
                height: '100%',              
              }} >
                <img
                  alt={singleImg.alt}
                  width={'100%'}
                  height={'100%'}
                  src={singleImg.isLocal ? localImg : singleImg.src}
                  key={index}
                  onClick={onClick}
                />
                <div className="resize-handle" onMouseDown={handleMouseDown} />
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default ImgBlock;



import React from 'react'
import GalleryItem from './galleryItem'


const PicsContainer = (props) => {
    const { items,selectimage } = props;
    return (
        <div className="galleryLayout">

            {items.map((item, index) => 
                <GalleryItem
                    key={index}
                    src={item.src}
                    alt={item.alt}
                    isSelected={item.isSelected}
                    onClick={()=>selectimage(index)}
                />
            )}


        </div>
    )
}

export default PicsContainer


// import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import { Link } from "react-router-dom";

const Drawer1 = ({menuItems, classes, handleDrawerClose}) => {
  return (
    <List>
        {menuItems?.map((item) => (
          <ListItemButton
           style={{textAlign: "right"}}
            // button={true}
            key={item.nameHe}
            component={Link}
            to={item.path}
            onClick={handleDrawerClose}
          >
            <ListItemText primary={item.nameHe} />
          </ListItemButton>
        ))}
      </List>
  )
}

export default Drawer1
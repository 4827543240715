
import  { useState, useEffect, useMemo } from 'react';

import AnimatedRoutes from './components/AnimatedRoutes';
import Footer from './components/Footer';
import Header from './components/Header';
import DefaultData1 from './data/DefaultData';


import { useSwipeable } from 'react-swipeable';

function Tutorials({ tutorialToDisplay }) {
 
  const DefaultData = tutorialToDisplay.data
    ? tutorialToDisplay.data
    : DefaultData1;

  // const [internship,setInternship] = useState([]);
  const configuration = {
    DefaultMainConteinerFontSize: '1.5rem',
    NextPrevBImage: 11,
    FooterStyle: {
      FooterColor: '#FFFFFF',
      FooterFontColor: '#000000',
      FooterFontSize: '1.5em',
    },
    // headerSquareColor: "#b1acaca8",
    MainBodyColor: '#b1d1dda2',
    numberOfPages: Object.keys(DefaultData).length,
    numberOfPagesColor: '#DC492B',
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [speech, setSpeech] = useState(false);
  const [maxPage, setMaxPage] = useState(4);
  const [slidDirection, setSlidDirection] = useState('rtl');
  const [answer, setAnswer] = useState({
    visible: false,
    correct: false,
    text: '',
  });
  const [allowNextPage, setAllowNextPage] = useState(true);
  const [specificInternship, setSpecificInternship] = useState([]);

  /* class Internship {
    constructor(internshipName, isDone) {
      this.internshipName = internshipName;
      this.isDone = isDone;
    }
  } */
  useMemo(() => {
    /*  axios */
    const userSpecificInternship = [
      'שירות באינטרנט',
      'שירות פרונטאלי',
      'נגישות מבני ציבור, סביבה ותשתיות',
      'נגישות השירות',
    ]; // need to be load from the databse for every user

    userSpecificInternship.forEach((element) => {
      setSpecificInternship((state) => [
        ...state,
        { internshipName: element, isDone: false },
      ]);
    });
  }, []);

  useEffect(() => {
    setMaxPage(configuration.numberOfPages);
  }, [configuration.numberOfPages]);

  // const styles = {
  //   container: {
  //     background: `repeating-linear-gradient(-45deg, #ffffffc4 1px, #ffffffd2 1px, ${configuration.MainBodyColor} 2px, ${configuration.MainBodyColor} 12px)`,
  //   },
  // };

  const handlers = useSwipeable({
    onSwipedLeft: () =>  allowNextPage? handleOnClickNextPage():null,
    onSwipedRight: () => allowNextPage? handleOnClickPrevPage():null,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const handleOnClickNextPage = (nextPage) => {
    if (nextPage !== null && nextPage !== undefined) {
      setCurrentPage(nextPage);
      setSlidDirection('rtl');
    } else {
      if (currentPage + 1 < maxPage) {
        setCurrentPage(currentPage + 1);
        setSlidDirection('rtl');
      } else {
        setCurrentPage(0);
        setSlidDirection('ltr');
      }
      setAnswer({ visible: false, correct: false, text: '' });
    }
  };

  const handleOnClickPrevPage = () => {
    setAnswer({ visible: false, correct: true, text: '' });
    setSlidDirection('ltr');
    if (currentPage < 0) return;
    if (currentPage > 0) {
      setCurrentPage((p) => p - 1);
      setAllowNextPage(true);
    }
  };

  return (
    <div className="Tutorials">
      <div className="container" >
        <Header
          currentPage={currentPage}
          maxPage={maxPage}
          headerSquareColor={configuration.headerSquareColor}
          numberOfPagesColor={configuration.numberOfPagesColor}
          text={DefaultData[currentPage].header.h1}
        />

       
          <AnimatedRoutes          
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            slidDirection={slidDirection}
            configuration={configuration}
            DefaultData={DefaultData[currentPage]}
            answer={answer}
            setAnswer={setAnswer}
            speech={speech}
            setSpeech={setSpeech}
            allowNextPage={allowNextPage}
            setAllowNextPage={setAllowNextPage}
            specificInternship={specificInternship}
            setSpecificInternship={setSpecificInternship}
            handlers={handlers}
          />
       
        <Footer
          DefaultData={DefaultData[currentPage]}
          NextPrevBImage={configuration.NextPrevBImage}
          FooterStyle={configuration.FooterStyle}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          slidDirection={slidDirection}
          setSlidDirection={setSlidDirection}
          setAnswer={setAnswer}
          answer={answer}
          maxPage={maxPage}
          speech={speech}
          setSpeech={setSpeech}
          allowNextPage={allowNextPage}
          setAllowNextPage={setAllowNextPage}
          handleOnClickNextPage={handleOnClickNextPage}
          handleOnClickPrevPage={handleOnClickPrevPage}
        />
      </div>
    </div>
  );
}

export default Tutorials;

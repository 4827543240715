// React
import { useState, useEffect, useRef } from 'react';
// Axios
import { axiosPrivate } from '../../../apis/axiosPrivate';
// Redux components
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentSlideNumber,setTemplateName,setContentPage,setTutorial} from '../../../store/slices/tutorialSlice';
import { GetAllCompanies } from '../../../store/slices/companySlice';
// Hooks
import useToggle from '@rooks/use-toggle';
// Components
import ControlButtons from './ControlButtons';
import TutorialPage from '../../tutorials/pages/tutorialPage';
import DefaultData from '../../tutorials/data/DefaultData';
import JSONInput from 'react-json-editor-ajrm';
import MenuControl from './menuControl/MenuContol';
import ContextMenu from './ContextMenu';
// CSS
import './CreateTutorialByText.css';
import './contextMenu.css';
// import { set } from 'react-hook-form';

const CreateTutorialByText = ({ setIsInTemplateEditor}) => {
  const dispatch = useDispatch();
  const jsonInputRef = useRef(null);

  //useSelector
  const { contentPage, templateName, tutorialSlides, internships, slideNumber } = useSelector((state) => state.tutorial);
  const { AllCompanies } = useSelector((state) => state.companies);
  
  const customToggleFunction = (v) => (v === 'RTL' ? 'LTR' : 'RTL');
  const [editorDirection, toggleDirection] = useToggle('LTR', customToggleFunction);
  const [companies, setCompanies] = useState([]);
  const [templatesNames, setTemplatesNames] = useState([]);
  // const [editorFontSize, setEditorFontSize] = useState('1rem');
  const [validCourseName, setValidCourseName] = useState(false);
  const [slideSelectedIndex, setSlideSelectedIndex] = useState(0);
  const [keyPressUpdate, setKeyPressUpdate] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);
const editorFontSize = '1rem';

  const modifiedCompany = (companies) => {
    const elementIndex = companies.findIndex((item) => item === 'תבניות בסיס');
    // Move the element to the first position
    if (elementIndex > -1) {
      let element = companies.splice(elementIndex, 1)[0];
      companies.unshift(element);
    }
    return companies;
  };
  
  const handleSaveTutorial = async (e) => {
    e.preventDefault();

    const saveToCompany = e.target.saveCompanies.value;

    try {
      const slideSizeOb = document.querySelector("#page-body")?.getBoundingClientRect();
      console.log('handleSaveTutorial',tutorialSlides,slideSizeOb);
      // add to tutorialSlides all of the slides in the pageStyle the value of the slideSizeOb.width and slideSizeOb.height and call it baseSize
      const updatedTutorialSlides = tutorialSlides.map((slide) => {
        const updatedSlide = {
          ...slide,
          pageStyle: {
            ...slide.pageStyle,
            baseSize: {
              width: slideSizeOb.width,
              height: slideSizeOb.height,
            },
          },
        }
        return updatedSlide;
      
      })       
     
      const response = await axiosPrivate.post('/tutorials/', {
        companyName: saveToCompany,
        TutorialName: templateName,
        Internships: internships,
        slides: updatedTutorialSlides,
      });
  
      // Check if the response has the expected data or status
      if (response.data && response.status === 200) {
        // The save was successful, you can log a message or show a confirmation to the user
        console.log('Tutorial saved successfully:', response.data);
        // Optionally, you can show a confirmation to the user here
        setSaveStatus(true);
        setTimeout(() => setSaveStatus(null), 5000);
      } else {
        // Handle unexpected response or error
        console.error('Unexpected response:', response);
        setSaveStatus('error');
        setTimeout(() => setSaveStatus(null), 5000);
      } 
      dispatch(GetAllCompanies());
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    const handleKeyDown = () => {
      // Hide the message when any key is pressed
      setSaveStatus(null);
    };

    // Attach the event listener when the component mounts
    document.addEventListener('keydown', handleKeyDown);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array means this effect runs once on mount


  const handleLoadSubmit = (e) => {
    e.preventDefault();

    const loadFromCompany = e.target.loadCompanies.value;
    const loadFromTemplate = e.target.loadTemplates.value;

    const [selectedCompany] = AllCompanies.filter(
      (company) => company.companyName === loadFromCompany
    );

    const [selectedTemplate] = selectedCompany.templates.filter(
      (template) => template.Name === loadFromTemplate
    );
    console.log('handleLoadSubmit',selectedTemplate);

    dispatch(setTutorial(selectedTemplate?.data));
  };

  const onChangeLoadCompany = (e) => {
    dispatch(GetAllCompanies());
    const [selectedCompany] = AllCompanies.filter(
      (company) => company.companyName === e.target.value
    );
    console.log('onChangeLoadCompany',selectedCompany);
    const templateNames = selectedCompany?.templates.map((template) => template.Name);
    setTemplatesNames(templateNames);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const draggingIndex = parseInt(e.dataTransfer.getData('text/plain'));

    if (draggingIndex === index) {
      return; // No need to update slides if the dragged slide and drop position are the same
    }

    const updatedSlides = [...tutorialSlides];
    const draggedSlide = updatedSlides.splice(draggingIndex, 1)[0]; // Remove the dragged slide
    updatedSlides.splice(index, 0, draggedSlide); // Insert the dragged slide at the new position

    dispatch(setTutorial(updatedSlides));

    if (draggingIndex === slideNumber) {
      dispatch(setCurrentSlideNumber(index)); // Update the current slide number if the dragged slide is the current slide
    } else if (
      (draggingIndex < slideNumber && index >= slideNumber) ||
      (draggingIndex > slideNumber && index <= slideNumber)
    ) {
      dispatch(setCurrentSlideNumber(slideNumber + (index > draggingIndex ? 1 : -1))); // Adjust the slide number if the drop position affects the current slide
    }
  };

  const handleSlideClick = (index) => {
    setSlideSelectedIndex(index);
    dispatch(setContentPage(tutorialSlides[index])); // Update the contentPage
    dispatch(setCurrentSlideNumber(index)); // Update the slideNumber
  };

// set useEffect to setup only once when the component is mounted
  useEffect(() => {
    setCurrentSlideNumber(0);
    setSlideSelectedIndex(0);
    dispatch(setTutorial(Object.values(DefaultData)));
    dispatch(setTemplateName(''));
    dispatch(setContentPage(Object.values(DefaultData)[0]));

    const modifiedCompanies = modifiedCompany(AllCompanies.map((company) => company.companyName));
    setCompanies(modifiedCompanies);
    const [selectedCompany] = AllCompanies.filter((company) => company.companyName === modifiedCompanies[0]);   
    const templateNames = selectedCompany?.templates.map((template) => template.Name);
    setTemplatesNames(templateNames);
    setIsInTemplateEditor(true);
      // eslint-disable-next-line 
  }, []);


// set useEffect to update the validCourseName every time the templateName is changed
  useEffect(() => {
    setValidCourseName(!!templateName);
  }, [templateName]);

// set useEffect to update the contentPage every time the slideNumber is changed
  useEffect(() => {
    async function updateOnKeyPress() {
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(5000);
      setKeyPressUpdate(false);
    }
    
    // set the contentPage to the correct index slide
    if (slideNumber !== null && tutorialSlides?.length > 0) {
      dispatch(setContentPage(tutorialSlides[slideNumber]));
      // update all of the tutorials
      
    } else if (tutorialSlides?.length > 0) {
      dispatch(setContentPage(tutorialSlides[0]));
    }

    updateOnKeyPress();
    // eslint-disable-next-line 
  }, [slideNumber, tutorialSlides,contentPage]);


  return (
    <div dir="LTR" className="layout">
      <div
        dir={`${editorDirection}`}
        style={{
          gridArea: 'lt',
          boxShadow: '5px 5px 5px',
          height: 'fit-content',
        }}
      >
        <ContextMenu />
        <div onKeyDown={() => setKeyPressUpdate(true)}>
          <JSONInput
          locale="en"
            ref={jsonInputRef}
            id="editor1"
            height="73vh"
            width="30vw"
            placeholder={contentPage} // data to display
            reset={false} // set true to reset value to placeholder
            waitAfterKeyPress={50}           
            background="#f5f5f5"
            onKeyPressUpdate={keyPressUpdate}
            onChange={(e) => {
              console.log('onChange', e.jsObject);
              if (!e.error) {
                dispatch(setContentPage(e.jsObject));
                const tempTutorialSlides = [...tutorialSlides];
                const newSlides = tempTutorialSlides.map((slide, index) =>
                  index === slideNumber ? e.jsObject : slide
                );
                dispatch(setTutorial(newSlides));
              }
            }}
            theme="light_mitsuketa_tribute"
            style={{
              body: { fontSize: editorFontSize, border: '2px solid black' },
              labelColumn: { fontSize: editorFontSize },
              labels: { fontSize: editorFontSize },
              contentBox: { fontSize: editorFontSize },
            }}
          />
        </div>
        <div style={{ border: '1px solid black' }}>
          <div
            style={{
              border: '1px solid black',
              padding: '0 5px',
              direction: 'rtl',
              display: 'flex',
            }}
          >
            <p style={{ padding: '0' }}>תפריט פעולות</p>
            {(saveStatus===true)?<p style={{ padding: '0', marginRight: '10px' , color:"green",fontWeight:"bold"}}>-נשמר בהצלחה-</p>:null}
            {(saveStatus==='error')?<p style={{ padding: '0', marginRight: '10px' , color:"red",fontWeight:"bold"}}>-שגיאה- השמירה נכשלה-</p>:null}
          </div>
          <div
            dir="rtl"
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid black',
            }}
          >
            <div style={{ marginLeft: '1%' }}>
              <p
                style={{
                  padding: '0',
                  margin: '0',
                  fontSize: '0.9rem',
                  fontWeight: 'bold',
                  color: validCourseName ? 'black' : 'white',
                }}
              >
                {validCourseName ? 'כיוון' : '-'}
              </p>
              <div
                style={{
                  display: 'flex',
                  margin: '3px',
                  flexDirection: 'row',
                  width: '100%',
                  padding: '1%',
                  borderLeft: '1px solid black',
                  height: '77%',
                  placeItems: 'center',
                }}
              >
                <button
                  style={{
                    height: '3.5rem',
                    width: '3.5rem',
                    marginLeft: '1%',
                  }}
                  onClick={() => toggleDirection()}
                >
                  {editorDirection}
                </button>
              </div>
            </div>
            <div style={{ marginLeft: '1%', display: validCourseName ? 'block' : 'none' }}>
              <p
                style={{
                  padding: '0',
                  margin: '0',
                  fontSize: '0.9rem',
                  fontWeight: 'bold',
                }}
              >
                {validCourseName ? 'שמירה' : 'יש להוסיף שם קורס'}
              </p>
              <form
                onSubmit={validCourseName ? handleSaveTutorial : null}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: '1%',
                  borderLeft: '1px solid black',
                  height: '80%',
                  placeItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '2px',
                    border: '1px solid black',
                    borderRadius: '5px',
                  }}
                >
                  <label htmlFor="saveCompanies">שמור עבור</label>
                  <select name="saveCompanies" id="saveCompanies">
                    {companies?.map((company, index) => (
                      <option key={index} value={company}>
                        {company}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  type="submit"
                  style={{
                    height: '3.5rem',
                    width: '3.5rem',
                    marginRight: '1%',
                    marginLeft: '1%',
                  }}
                >
                  שמור
                </button>
              </form>
            </div>
            <form style={{ width: '100%' }} onSubmit={handleLoadSubmit}>
              <p
                style={{
                  padding: '0',
                  margin: '0',
                  fontSize: '0.9rem',
                  fontWeight: 'bold',
                }}
              >
                טעינה
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: '1%',
                }}
              >
                <div>
                  <p style={{ padding: '0', margin: '0', fontSize: '0.9rem' }}>
                    תבניות חברה
                  </p>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      style={{
                        paddingLeft: '1%',
                        margin: '1%',
                        borderLeft: '1px solid black',
                      }}
                    >
                      <p
                        style={{
                          padding: '0',
                          margin: '0',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                        }}
                      >
                        בחר חברה
                      </p>
                      <select
                        name="loadCompanies"
                        id="loadCompanies"
                        onChange={onChangeLoadCompany}
                      >
                        {companies?.map((company, index) => (
                          <option key={index} value={company}>
                            {company}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{
                        paddingLeft: '1%',
                        margin: '1%',
                        borderLeft: '1px solid black',
                      }}
                    >
                      <p
                        style={{
                          padding: '0',
                          margin: '0',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                        }}
                      >
                        בחר תבנית
                      </p>
                      <select
                        name="loadTemplates"
                        id="loadTemplates"
                        style={{ minWidth: '100px' }}
                      >
                        {templatesNames.map((template, index) => (
                          <option key={index} value={template}>
                            {template}
                          </option>
                        ))}
                      </select>
                    </div>
                    <input
                      type="submit"
                      value="טען"
                      style={{
                        height: '2.5rem',
                        width: '5rem',
                        marginRight: '1%',
                        marginLeft: '1%',
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="right-box">
        <div dir="rtl" className="view-box">
          <MenuControl
            slideNumber={slideNumber}
            showText={false}
            showTitle={true}
          />
          {contentPage === null ? (
            <p>view large slide</p>
          ) : (
            <TutorialPage
              DefaultData={contentPage}
              answer={{ visible: false }}
              setAnswer={() => { }}
              configuration={null}
              speech={false}
              slidDirection="rtl"
              currentPage={null}
              setCurrentPage={() => { }}
              allowNextPage={true}
              setAllowNextPage={() => { }}
              specificInternship={[]}
              setSpecificInternship={() => { }}
              contenteditable={true}
              size="large"
            />
          )}
        </div>
        <div dir="rtl" className="bottom-box">         
          {tutorialSlides?.map((page, index) => {
            const slideNumber = index + 1; // Increment index by 1
            return (
              <div
                className={`page-box ${index === slideSelectedIndex ? 'selected' : ''}`}
                key={index}
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => handleDragOver(e, index)}
                onDrop={(e) => handleDrop(e, index)}
                onClick={() => handleSlideClick(index)}
              >
                <div className="slide-number">{slideNumber}</div> {/* Display slide number with index + 1 */}
                <TutorialPage                 
                  DefaultData={page}
                  answer={{ visible: false }}
                  setAnswer={() => { }}
                  configuration={null}
                  speech={false}
                  slidDirection="rtl"
                  currentPage={index}
                  setCurrentPage={() => { }}
                  allowNextPage={true}
                  setAllowNextPage={() => { }}
                  specificInternship={[]}
                  setSpecificInternship={() => { }}
                  contenteditable={false}
                  size="mini"
                />
                <ControlButtons
                  key={'f' + index}
                  index={index}
                  slides={tutorialSlides}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CreateTutorialByText;

import { useEffect, useState } from "react";
import CustomButton from "./Button";
import { Button } from "@mui/material";
import ButtonImage1 from "../assetes/images/buttons/1.png";
import ButtonImage2 from "../assetes/images/buttons/2.png";
import ButtonImage3 from "../assetes/images/buttons/3.png";
import ButtonImage4 from "../assetes/images/buttons/4.png";
import ButtonImage5 from "../assetes/images/buttons/5.png";
import ButtonImage6 from "../assetes/images/buttons/6.png";
import ButtonImage7 from "../assetes/images/buttons/7.png";
import ButtonImage8 from "../assetes/images/buttons/8.png";
import ButtonImage9 from "../assetes/images/buttons/9.png";
import ButtonImage10 from "../assetes/images/buttons/10.png";
import ButtonImage11 from "../assetes/images/buttons/11.png";
import ButtonImage12 from "../assetes/images/buttons/12.png";
import useToggle from "@rooks/use-toggle";
import logo1 from "../assetes/logo/logo1.png";
import { useDispatch, useSelector } from "react-redux";
import { incrementNumberOfCorrectAnswers } from "../../../store/slices/tutorialSlice";
import HearingIcon from "@mui/icons-material/Hearing";
import HearingDisabledIcon from "@mui/icons-material/HearingDisabled";
// import axios from "../../../apis/axios";
import { axiosPrivate } from "../../../apis/axiosPrivate";

const Footer = ({
  maxPage,
  currentPage,
  setCurrentPage,
  setSlidDirection,
  NextPrevBImage,
  setAnswer,
  answer,
  // FooterFontSize,
  FooterStyle,
  speech,
  setSpeech,
  allowNextPage,
  setAllowNextPage,
  DefaultData,
  handleOnClickNextPage,
  handleOnClickPrevPage
}) => {
  const dispatch = useDispatch();
  //using redux useSelector to get from tutorial the answerArcive
  const answerArcive = useSelector((state) => state.tutorial.answerArchive);
  //using redux useSelector to get from tutorial the statistics
  const statistics = useSelector((state) => state.tutorial.statistics);
  //using redux useSelector to get from users the current user
  const currentUser = useSelector((state) => state.users.CornetUser);
  // push the answerArcive to the statistics
  const statisticsWithAnswerArcive = { ...statistics, answerArcive, currentUser };
  const ButtonImageArray = [
    ButtonImage1,
    ButtonImage2,
    ButtonImage3,
    ButtonImage4,
    ButtonImage5,
    ButtonImage6,
    ButtonImage7,
    ButtonImage8,
    ButtonImage9,
    ButtonImage10,
    ButtonImage11,
    ButtonImage12,
  ];
  const nextPage = DefaultData.router !== undefined ? DefaultData.router.nextPage : null;
     // eslint-disable-next-line 
  const [buttonsImage, SetButtonsImage] = useState(NextPrevBImage);
  const customToggleFunction = (v) => {
    setSpeech(!speech);
    return v === "start" ? "stop" : "start";
  };
  const [value3, toggleValue3] = useToggle("start", customToggleFunction);

  const handleNextPage = (currentPage, nextPage) => {
    if (nextPage !== null && nextPage !== undefined) {
      return "/users/page" + nextPage.toString();
    } else {
      return currentPage + 1 < maxPage
        ? "/users/page" + (currentPage + 1).toString()
        : "/users";
    }
  };
  useEffect(() => {
    if (answer.correct) {
      dispatch(incrementNumberOfCorrectAnswers())
      axiosPrivate.post(`/employees/api/statistics/`, statisticsWithAnswerArcive);
    };
  }
    // eslint-disable-next-line 
    , [answer.correct]);


  return (
    <div className="buttonsSection"  style={FooterStyle} >
      <div id="11" style={styles.header} >
        {/* stop/start reading */}
        <div className="readButton">
        <Button          
          sx={styles.headerButton}
          variant="outlined"
          type="submit"
          onClick={toggleValue3}
        >
          {value3 === "start" ? <HearingIcon /> : <HearingDisabledIcon />}
        </Button>
        </div>
        <div style={styles.logoContainer}>
          <img src={logo1} style={styles.logo} alt="נגישות לעסקים" />
        </div>
      </div>
      <div style={styles.chapterContainer}>
        <p style={styles.chapterText}>
          {`פרק:`} {DefaultData.chapter ? DefaultData.chapter : ""}
        </p>
      </div>
      <div style={styles.buttonContainer}>
        {/* previous button */}
        <CustomButton
          rotate={180}
          imageSrc={ButtonImageArray[buttonsImage]}
          alt="הקודם"
          to={
            currentPage === 0
              ? "/users/"
              : currentPage - 1 === 0
              ? "/"
              : "/users/page" + (currentPage - 1).toString()
          }
          onClick={handleOnClickPrevPage}
        />
        {/* next button */}
        {allowNextPage ? (
          <CustomButton
            allowNextPage={allowNextPage}
            imageSrc={ButtonImageArray[buttonsImage]}
            alt="הבא"
            to={handleNextPage(currentPage, nextPage)}
            onClick={() => {
              handleOnClickNextPage(nextPage);
            }}
          />
        ) : (
          <CustomButton
            imageSrc={ButtonImageArray[buttonsImage]}
            alt="הבא"
            to={currentPage}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  header: {
    display: "flex",
    color: "rgb(255, 255, 255)",
    alignItems: "center",
    justifyItems: "right",
  },
  headerButton: {
    justifySelf: "end",
    width: "3rem",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "Center",
  },
  logo: {
    width: "6rem",
  },
  chapterContainer: {
    display: "grid",
    placeItems: "center",
  },
  chapterText: {
    margin: 0,
    fontSize: "1.1em",
    fontWeight: "bold",
  },
  buttonContainer: {
    display: "flex",
    gridTemplateColumns: "1fr 1fr",
    placeItems: "center",
    placeContent: "center",
    justifySelf: "end",
  },
  // ... (add more styles as needed)
};


export default Footer;

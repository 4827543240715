// eslint-disable-next-line
import React, { useEffect,useState } from "react";
// import axios from "../../apis/axios";
import {axiosPrivate} from '../../apis/axiosPrivate';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { TextField } from "@mui/material";
import rtlPlugin from "stylis-plugin-rtl";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// eslint-disable-next-line
import {setSelectedCompany, EditCompany} from "../../store/slices/companySlice";
// import { axiosUpdateCompany } from "../../routesControlers/routesControlers";



const axiosUpdateCompany = (selectedCompany, companyName, logo, color) => {
    let status = null;
    
try {
  axiosPrivate
    .put('/companies/', {
      selectedCompany: selectedCompany,
      companyName: companyName,
      companyIcon: logo,
      backgroundColor: color,
    })    
    .then((status = 'OK'))
    .catch((err) => console.error(err));
    if (status === 'OK') {
      axiosPrivate
  .put('/employees/updateEmployeesCompany/', {
    selectedCompany: selectedCompany,
    companyName: companyName,
  })
    .then((status = 'OK'))
    .catch((err) => console.error(err));
    }
  return status;
  
} catch (error) {
  console.error(error) 
}
};


//need to add formic
function EditNewCostumerPage() {
//styles
  const styles = {
    login: {
      placeItems: "center",
      margin: "auto",
      backgroundColor: "white",
      width: "500px",
    },
    h1: { display: "flex", justifyContent: "center" },
    form: {
      display: "grid",
      padding: "2rem",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  };
  
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedCompanyName = useSelector(({ companies }) => companies.SelectedCompany);
  const Companies = useSelector(({ companies }) => companies?.AllCompanies);
 
  var selectedCompany = Companies?.filter((company) => company.companyName === selectedCompanyName);
  console.log(selectedCompany);

  selectedCompany = selectedCompany[0];

  

  const handleSubmit = (e) => {
    e.preventDefault();
    const { companyName, logo, color } = e.target;
    dispatch(
      EditCompany({
        selectedCompany: selectedCompany.companyName,
        companyName: companyName.value,
        companyIcon: logo.value,
        backgroundColor: color.value,
      })
    );
    // const companies = useSelector(({companies}) => companies.AllCompanies)
    const status = axiosUpdateCompany(
      selectedCompany.companyName,
      companyName.value,
      logo.value,
      color.value
    );
    if (status === "OK") {
      navigate("/admin/Costumers/");
    }
    dispatch(setSelectedCompany(companyName.value));
  };
  return (
    <CacheProvider value={cacheRtl}>
      <div style={{ display: "grid", width: "100%", height: "90%" }}>
        <div style={styles.login}>
          <div style={styles.header}>
            <div style={{ width: "90%" }}>
              <h1 style={styles.h1}>ערוך פירטי חברת {selectedCompanyName} </h1>
            </div>
          </div>

          <form style={styles.form} onSubmit={handleSubmit}>
            <TextField
              id="companyName"
              fullWidth
              sx={{ height: "3rem", marginTop: "0" }}
              defaultValue={selectedCompany?.companyName}
              type="text"
              label="שם חברה"
              // helperText={}
            />
            <TextField
              id="logo"
              fullWidth
              sx={{ height: "3rem", marginTop: "2.5rem" }}
              // autoComplete="username"
              type="url"
              label="קישור ללוגו חברה"
              defaultValue={selectedCompany?.companyIcon}
              // helperText={'לוגו חברה'}
              // FormHelperTextProps={{
              //   style: styles.FormHelperText,
              // }}
            />
            <TextField
              id="color"
              fullWidth
              sx={{ height: "3rem", marginTop: "2.5rem" }}
              // autoComplete="username"
              type="text"
              defaultValue={selectedCompany?.backgroundColor}
              // value="white"
              label="צבע רקע של הלוגו"
              // helperText={}
            />
            <input
              style={{ marginTop: "2%", justifySelf: "end", width: "5rem" }}
              type="submit"
              value="עדכן"
            />
          </form>
        </div>
      </div>
    </CacheProvider>
  );
}

export default EditNewCostumerPage;


import {createSlice} from '@reduxjs/toolkit';
import {createAsyncThunk } from '@reduxjs/toolkit';
import {axiosPrivate} from '../../apis/axiosPrivate';

export const fetchUsersItems = createAsyncThunk(
  'users/fetchUsersItems',
  async () => {
    const response = await axiosPrivate.get('/employees/');
    return response.data;
  }
);

export const fetchUsersByCompany = createAsyncThunk(
  'users/fetchUsersByCompany',
  async (selectedCompanyName, role) => {
    const response = await axiosPrivate.post('/employees/byCompany', {
      companyName: selectedCompanyName,
      role: role,
    });
    return response.data;
  }
);

export const addUserAsync = createAsyncThunk(
  'users/addUserAsync',
  async (user) => {
    try {      
      await axiosPrivate.post(`/employees`, user);
      return user;
    } catch (error) {
      console.log('!!!!addUserAsync error:',error);
      throw error;
    }
  }
);



export const deleteUsersAsync = createAsyncThunk(
  'users/deleteUsersAsync',
  async (email, thunkAPI) => {
    try {
      await axiosPrivate.delete(`/employees/deleteUser/${email}`);
      return email;
    } catch (error) {
      console.error('deleteUsersAsync error:', error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const updateUserAsync = createAsyncThunk(
  'users/updateUsersAsync',
  async (user, thunkAPI) => {
    try {
      await axiosPrivate.put('/employees', user);
      return user;
    } catch (error) {
      console.error('updateUserAsync error:', error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UsersSlice = createSlice({
  name: 'users',
  initialState: {
    CornetUser: null,
    Token: null,
    AllUsers: [],
    UsersSliceIsLoading: false,
    UsersSliceHasError: false,
  },
  reducers: {
    setToken: (state, { payload }) => {
      state.Token = payload;
    },
    setContentUser: (state, { payload }) => {
      state.CornetUser = payload;
    },
    SetUsers: (state, { payload }) => {
      state.AllUsers = payload;
    },
    updateUser: (state, { payload }) => {
      state.AllUsers = state.AllUsers.map((user) => {
        if (user.email === payload.email) {
          const newDataUser = payload.newUserDistils;
          delete payload.newUserDistils;
          payload.name = newDataUser?.name ? newDataUser?.name : user.name;
          payload.email = newDataUser?.email ? newDataUser?.email : user.email;
          payload.tutorialsAllowed = newDataUser?.selectedTutorials
            ? newDataUser?.selectedTutorials
            : user.tutorialsAllowed.internships;
          return payload;
        }
        return user;
      });
    },

    AddUsers: (state, { payload }) => {
      state.AllUsers.push(payload);
    },

    deleteUsers: (state, { payload }) => {
      state.AllUsers = state.AllUsers.filter(
        (Users) => Users.email !== payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersItems.pending, (state) => {
        state.UsersSliceIsLoading = true;
      })
      .addCase(fetchUsersItems.fulfilled, (state, action) => {
        state.AllUsers = action.payload;
        state.UsersSliceIsLoading = false;
      })
      .addCase(fetchUsersItems.rejected, (state) => {
        state.UsersSliceIsLoading = false;
        state.UsersSliceHasError = true;
      })
      .addCase(fetchUsersByCompany.pending, (state) => {
        state.UsersSliceIsLoading = true;
      })
      .addCase(fetchUsersByCompany.fulfilled, (state, action) => {
        state.AllUsers = action.payload;
        state.UsersSliceIsLoading = false;
      })
      .addCase(fetchUsersByCompany.rejected, (state) => {
        state.UsersSliceIsLoading = false;
        state.UsersSliceHasError = true;
      })
      .addCase(addUserAsync.pending, (state) => {
        state.UsersSliceIsLoading = true;
      })
      .addCase(addUserAsync.fulfilled, (state, action) => {
        state.AllUsers.push(action.payload);
        state.UsersSliceIsLoading = false;
      })
      .addCase(addUserAsync.rejected, (state) => {
        state.UsersSliceIsLoading = false;
        state.UsersSliceHasError = true;
      })
      .addCase(deleteUsersAsync.pending, (state) => {
        state.UsersSliceIsLoading = true;
      })
      .addCase(deleteUsersAsync.fulfilled, (state, action) => {
        state.AllUsers = state.AllUsers.filter(
          (Users) => Users.email !== action.payload
        );
        state.UsersSliceIsLoading = false;
      })
      .addCase(deleteUsersAsync.rejected, (state) => {
        state.UsersSliceIsLoading = false;
        state.UsersSliceHasError = true;
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.UsersSliceIsLoading = true;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        console.log("updateUserAsync.fulfilled", action.payload);
        state.AllUsers = state.AllUsers.map((user) => {
          if (user.email === action.payload.email) {
            const newDataUser = action.payload.newUserDistils;
            delete action.payload.newUserDistils;
            action.payload.name = newDataUser?.name ? newDataUser?.name : user.name;
            action.payload.email = newDataUser?.email ? newDataUser?.email : user.email;
            action.payload.tutorialsAllowed = newDataUser?.selectedTutorials
              ? newDataUser?.selectedTutorials
              : user.tutorialsAllowed.internships;
            return action.payload;
          }
          return user;
        });
        state.UsersSliceIsLoading = false;
      })
      .addCase(updateUserAsync.rejected, (state) => {
        state.UsersSliceIsLoading = false;
        state.UsersSliceHasError = true;
      });
  },
  
});

export const {
  setToken,
  setContentUser,
  SetUsers,
  updateUser,
  AddUsers,
  deleteUsers,
} = UsersSlice.actions;

export default UsersSlice.reducer;
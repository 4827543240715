// BoxAria.jsx
import BoxComponent from "./BoxComponent";

const convertFunction = (selectedUserTutorials, i) => {
  const tutorialName = selectedUserTutorials[i]?.tutorialName || '';
  const tutorialInternships = selectedUserTutorials[i]?.internships.toString() || '';
  return `הדרכות: ${tutorialName} התמחויות: ${tutorialInternships}`;
};

const BoxAria = ({numOfTutorials,selectedUserTutorials}) => {
 const numOfTutorialsArray = [...Array(numOfTutorials).keys()];  

 numOfTutorialsArray.shift();

  return (
    <div className="internships_Display_Box">
    <div><p style={{margin:"0"}}>נבחר</p></div>
     {numOfTutorialsArray.map((num,index) =>{
        const title = convertFunction(selectedUserTutorials,index);
        return <BoxComponent key={index} num={num} visible={true} title={title}/>
     })}

      
        
       
      </div>
  )
}

export default BoxAria
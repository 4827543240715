
import { useEffect, useState } from 'react';
import StylingMenu from '../StylingMenu';

const TextBlock = (props) => {
  const {
    body,
    refs,
    type,
    index1, index2,
    textItem,
    style,
    color,
    contenteditable,
    selectedElement, setSelectedElement,
    isElementSelected, setIsElementSelected,
    isListElementSelected, setIsListElementSelected,
    handleInput, handleStyleChange, handleDeleteElement,
    dragOver, dragStarted, dragDropped
  } = props;

  const [text, setText] = useState(textItem);

  const Element = type;

  // Update text state when props.textItem changes
  useEffect(() => {
    setText(textItem);    
  }, [textItem]);




  // Handle text input changes
  const handleTextInput = (e) => {
    const newText = e.target.value;
    setText(newText);
    handleInput(index1, index2, newText);
  };

  return (
    <div 
    key={`outerText-${index1}`}
    className={'textElementContainer'}
    draggable={contenteditable} // Add the draggable attribute here
    onDragStart={(e) => dragStarted(e, index1)} // Handle the drag start event here
    onDragOver={(e) => 
      {e.preventDefault()
      dragOver(e, index1)}}  
     >
      {contenteditable && isElementSelected && !isListElementSelected && (
        <StylingMenu
          handleStyleChange={handleStyleChange}
          selectedElement={selectedElement}
          setIsElementSelected={setIsElementSelected}
          setSelectedElement={setSelectedElement}
          setIsListElementSelected={setIsListElementSelected}
          handleDeleteElement={handleDeleteElement}    
          elementId={`textArea-${index1}`}
        />
      )}
      <Element
        key={`inner-${index1}-${index2}`}
        dir="rtl"
        ref={(el) => (refs.current[index1][index2] = el)}
        draggable={contenteditable}
        suppressContentEditableWarning
        style={style ? { color, ...style } : { color }}
        onDrop={dragDropped}        
        >
        {contenteditable ? (
          <textarea       
            id= {`textArea-${index1}`}
            value={text}
            style={{ ...style, color, height: 'auto', overflow: 'hidden', rows: 1,width:'max-content' }}
            rows={Math.max(1, Math.ceil(text?.length / 60))}            
            onInput={handleTextInput}
            onDragOver={(e) => dragOver(e, index1)}
            onDragStart={(e) => dragStarted(e, index1)}
            onFocus={() => {
              setIsListElementSelected(body[index1].type === 'uoList' || body[index1].type === 'oList');
              setSelectedElement({ index1, index2 });
              setIsElementSelected(true);
            }}
          />
        ) : (
          text
        )}
      </Element>
    </div>
  );
};

export default TextBlock;

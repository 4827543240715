import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios from '../../apis/axios';
import {axiosPrivate} from '../../apis/axiosPrivate';

// import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
export const fetchCompanyData = createAsyncThunk(
  "company/fetchCompanyData",
  async (companyId) => {
   
    const response = await axiosPrivate.get(`/company/${companyId}`);
    return response.data;
  }
);


export const  GetAllCompanies = createAsyncThunk (
  "company/getallCompanies",
   async () => {
     try {
     console.log("get all companies");
      
      const response = await axiosPrivate.get("/companies/");    
      return response.data;     
      
    } catch (error) {
       console.error("error", error);      
    }
  }
);


export const FetchOneCompanyData = createAsyncThunk(
  "company/fetchOneCompanyData",
  async (companyName) => { 
    try {
      if ( companyName !== axiosPrivate) {
        const response = await axiosPrivate.get(`/companies/companyDetails/${companyName}`) 
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  }
);

export const DeleteCompanyAsync = createAsyncThunk(
  "company/deleteCompany",
  async (selectedCompanyName) => {
    try {
      await axiosPrivate
        .delete(`/companies/deleteCompanies/${selectedCompanyName}`)       
        
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateCompanyStatusAsync = createAsyncThunk(
  "company/updateCompanyStatus",
  async (company) => {
    console.log("updateCompanyStatusAsync", company)
    try {
       await axiosPrivate
        .put(`/companies/updateCompanies/${company.companyName}`, company)
        
    } catch (error) {

      console.error(error);
    }
  }
);




const companySlice = createSlice({
  name: "company",
  initialState: {
    CornetCompany: null,
    TutorialsNamesForCurrentCompany: null,
    CornetCompanyData: null,
    SelectedCompany: null,
    AllCompanies:[],
    isLoading: false,
    hasError: false
  },
  reducers: {
    SetCompanies: (state, { payload }) => {
      state.AllCompanies = payload;
    },

    setCornetCompany: (state, { payload }) => {
      state.CornetCompany = payload;
    },
    SetCornetCompanyData: (state, { payload }) => {
      state.CornetCompanyData = payload;
    },
    SetTutorialsNamesForCurrentCompany: (state, { payload }) => {
      state.TutorialsNamesForCurrentCompany = payload;
    },
    setSelectedCompany: (state, { payload }) => {
      state.SelectedCompany = payload;
    },

    AddCompany: (state, { payload }) => {
      state.AllCompanies.push(payload);
    },
    DeleteCompany: (state, { payload }) => {
      state.AllCompanies = state.AllCompanies.filter(
        (company) => company.companyName !== payload.companyName
      );
    },
    EditCompany: (state, { payload }) => {
        const {companyName,companyIcon,backgroundColor,status} = payload;          
        const index = state.AllCompanies.findIndex(
          (company) => company.companyName === state.SelectedCompany
        );
        state.AllCompanies[index].companyName = companyName;
        state.AllCompanies[index].companyIcon = companyIcon;
        state.AllCompanies[index].backgroundColor = backgroundColor;
        state.AllCompanies[index].status = status;      
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllCompanies.fulfilled, (state, action) => {
        state.AllCompanies = action.payload;
      })
      .addCase(GetAllCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllCompanies.rejected, (state) => {
        state.hasError = true;
      })
      .addCase(fetchCompanyData.fulfilled, (state, action) => {
        state.CornetCompany = action.payload;
      })
      .addCase(fetchCompanyData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCompanyData.rejected, (state) => {
        state.hasError = true;
      })
      .addCase(FetchOneCompanyData.fulfilled, (state, action) => {
        const getTutorialsNamesForCurrentCompany = (companyData) => {
          console.log(companyData);
          let tutorialsNames = [];
          if (companyData?.templates) {
            tutorialsNames = companyData.templates.map((tutorial) => tutorial.Name);
          }
          return tutorialsNames;
        };
  
        const a = getTutorialsNamesForCurrentCompany(action.payload);
        state.TutorialsNamesForCurrentCompany = a;
        state.CornetCompanyData = action.payload;
      })
      .addCase(FetchOneCompanyData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(FetchOneCompanyData.rejected, (state) => {
        state.hasError = true;
      })
      .addCase(DeleteCompanyAsync.fulfilled, (state, action) => {
        state.AllCompanies = state.AllCompanies.filter(
          (company) => company.companyName !== action.meta.arg
        );
      })
      .addCase(DeleteCompanyAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(DeleteCompanyAsync.rejected, (state) => {
        state.hasError = true;
      })
      .addCase(updateCompanyStatusAsync.fulfilled, (state, action) => {
        const { companyName, companyIcon, backgroundColor, status } = action.meta.arg;
        console.log('payload', action);
        console.log('my payload1', companyName, companyIcon, backgroundColor, status);
  
        const index = state.AllCompanies.findIndex((company) => company.companyName === companyName);
        if (index === -1) return;
        state.AllCompanies[index].companyName = companyName;
        state.AllCompanies[index].companyIcon = companyIcon;
        state.AllCompanies[index].backgroundColor = backgroundColor;
        state.AllCompanies[index].status = status;
      })
      .addCase(updateCompanyStatusAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCompanyStatusAsync.rejected, (state) => {
        state.hasError = true;
      });
  },
  
  
});

export const {
  SetTutorialsNamesForCurrentCompany,
  SetCornetCompanyData,
  setCornetCompany,
  SetCompanies,
  AddCompany,
  DeleteCompany,
  EditCompany,
  setSelectedCompany,
} = companySlice.actions;

export default companySlice.reducer;








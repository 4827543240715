/* import mem from 'mem';
import axios from './axios';

const refreshTokenFn = async () => {
   
  try {
    const response = await axios.get('/refresh'); 
    const { accessToken } = response.data;
    document.cookie = `Authorization=${accessToken}; path=/;`;
    if (!accessToken) {
    document.cookie = `Authorization=''; path=/;`;
    return;
    }
    return accessToken;
  } catch (error) {
  console.log('refreshTokenFn',error);
  throw new Error('Failed to refresh token');
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
 */

import mem from 'mem';
import axios from './axios';

const refreshTokenFn = async () => {
  try {
    const response = await axios.get('/refresh');
    const { accessToken } = response.data;

    if (accessToken) {
      document.cookie = `Authorization=${accessToken}; path=/;`;
      return accessToken;
    } else {
      // Handle the case where accessToken is missing more gracefully
      throw new Error('Access token not found');
    }
  } catch (error) {
    // Handle axios errors more gracefully
    console.error('refreshTokenFn', error);
    throw new Error('Failed to refresh token');
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});

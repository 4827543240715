import PicsContainer from './picsContainer';
import CloseIcon from '@mui/icons-material/Close';
import './picsContainer.css'

const ExplorerWindow = (props) => {
  const { selectImage , items, setShowGalleryImages ,handleSubmitGallery, PIndex,selectedImageName} = props
  return (
    <div style={{position: "absolute",
     top: "2rem",
      margin: "2rem",
       padding: "2rem", 
       width: "max-content",
       height: "max-content",
       backgroundColor: "rgba(255, 255, 255, 0.89)",
       zIndex: "99999999999999999",
       border: "3px solid black"
       }}>
{/* This DIV will be displayed in front of the HTML. */}
{/* </div>

    <div style={{
      position: 'absolute',
      top:'2rem',
      margin:"2rem",
      padding:"2rem",     
      width: 'max-content', 
      height: 'max-content', 
      backgroundColor: 'rgb(255 255 255 / 89%)',
       zIndex: '9999999999',
       border:"3px solid black"}}> */}
    <div className="explorer-window">
      <PicsContainer selectimage={selectImage} items={items} /> 
    </div>

    <button onClick={()=>
    {
      handleSubmitGallery(PIndex,selectedImageName)
      setShowGalleryImages(false)
    }
      } className="select-button">
       אשר
    </button> 
           
    <CloseIcon onClick={()=>setShowGalleryImages(false)} className="close-button" />              
    </div>
  );
};

export default ExplorerWindow;

import { useEffect, useCallback, useState } from "react";

const useContextMenu = (elementId) => {
  // console.log("elementId", elementId);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);

  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      const mainBody = document.getElementById(elementId);
      if (mainBody && (mainBody === event.target || mainBody.contains(event.target))) {
        setAnchorPoint({ x: event.clientX, y: event.clientY });
        setShow(true);
      }
    },
    [setShow, setAnchorPoint, elementId]
  );

  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

  useEffect(() => {
    const mainBody = document.getElementById(elementId);
    
    if (mainBody) {
      mainBody.addEventListener("contextmenu", handleContextMenu);
    }
    document.addEventListener("click", handleClick);
    return () => {
      if (mainBody) {
        mainBody.removeEventListener("contextmenu", handleContextMenu);
      }
      document.removeEventListener("click", handleClick);
    };
  }, [elementId, handleContextMenu, handleClick]);

  return [anchorPoint, show];
};

export default useContextMenu;

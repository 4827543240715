import { useEffect, useState } from 'react';
import StylingMenuForList from '../StylingMenuForList';


const List = ({
  index1,
  item,
  index,
  dir,
  contenteditable,
  handleKeyDown,
  handleInput,
  onBlur,
  style,
  selectedElement,
  setSelectedElement,
  dragStartedInsideList,
  dragOverInsideList,
  body,
  setIsListElementSelected,
  setIsElementSelected,
  dragDroppedInArray,
  draggedStartedListIndex,
  draggedOverIndexInsideList,type,pageData,setUpdatedPageData

  
}) => {
  const [text, setText] = useState(item);

  useEffect(() => {
    setText(item);
  }
  , [item]);

  const handleTextInput = (e) => {
    const newText = e.target.value;
    setText(newText);
    handleInput(index1, index, newText);
  };

  return (
    
    
    
    <li
      draggable={contenteditable} // Add the draggable attribute here
      onBlur={onBlur}
      key={index}
      dir={dir}
      style={style}
      suppressContentEditableWarning={true}
      onDrop={() => dragDroppedInArray(index1, draggedStartedListIndex,draggedOverIndexInsideList)}
      onDragStart={(e) => dragStartedInsideList(e, index)}
      id={`List-${index1}-${index}`}
    >
      {contenteditable ? (
        <>
         <StylingMenuForList    
         body={body}
         pageData={pageData}
         setUpdatedPageData={setUpdatedPageData} 
        selectedElement={selectedElement}
        setIsElementSelected={setIsElementSelected}
        setSelectedElement={setSelectedElement}
        setIsListElementSelected={setIsListElementSelected}
       
        elementId={`List-${index1}-${index}`}

        elementType={type}
/>
        <input
          type="text"
          value={text}
          style={style}
          onChange={handleTextInput}
          onKeyDown={handleKeyDown}
         
          onDragOver={(e) => {
            e.preventDefault();
            dragOverInsideList(e, index);
          }}
          onFocus={() => {
            setIsListElementSelected(body[index1].type === 'uoList' || body[index1].type === 'oList');
            setSelectedElement({ index1, index2: index });
            setIsElementSelected(true);
          }}
        />
        </>
      ) : (
        item
      )}
    </li>

  );
};

export default List;

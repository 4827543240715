const CustomExpandIcon = ({ onExpand, expanded, record }) => {
  const text = expanded ? '\u2191' : '\u2193';

  const buttonStyles = {
    color: 'blue',
    cursor: 'pointer',
    width: '20px', // Specify the units (e.g., 'px') for width and height
    height: '20px',
    background: 'none',
    border: 'none',
    fontSize: 'x-large',
  };

  return (
    <button
      className="expand-row-icon"
      style={buttonStyles}
      onClick={(e) => {
        onExpand(record, e);
      }}
    >
      {text}
    </button>
  );
};

export default CustomExpandIcon;

//Register.jsx
import { useState, useEffect } from "react";
import "./register.css";
import { addUserAsync } from "../../../../store/slices/usersSlice";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { useForm } from "react-hook-form";
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import { UserFormControl } from "./UserFormControl";
import axios from "../../../../apis/axios";

const Register = () => {
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();
  const [selectInternshipValue, setSelectInternshipValue] = useState();
  const [selectTutorialValue, setSelectTutorialValue] = useState();
  const [usersFromFile, setUsersFromFile] = useState([]);
  const [actionFileStatus, setActionFileStatus] = useState("");
  const [file, setFile] = useState(null);
  const [selectedUserTutorials, setSelectedUserTutorials] = useState([]);
  const [numOfTutorials, setNumOfTutorials] = useState(0);
  const [internships, setInternships] = useState([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [newUserDistils, setNewUserDistils] = useState({name:'', email:'', password:'', selectedTutorials:[]});
  const CornetCompanyName = useSelector(
    ({ companies }) => companies.CornetCompany
  );
  const tutorials = useSelector(
    ({ companies }) => companies.TutorialsNamesForCurrentCompany
  );
  const CornetCompanyData = useSelector(
    ({ companies }) => companies.CornetCompanyData
  );

  // values of the dropdown
  let { selectTutorialOptions, selectInternshipOptions } = dropdownValues(
    internships,
    tutorials
  );
  //dropdown styles
  const { labelStyle, style, optionStyle, dropdownStyle } = dropDownStyles();

  const handleDownloadButton = (url, filename) => {
   
      axios.get(url, { responseType: 'blob' })
      .then(response => {
    // Create a blob object from the response data
    const blob = new Blob([response.data], { type: 'text/plain' });

    // Create a temporary URL for the blob object
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();

    // Release the temporary URL
    window.URL.revokeObjectURL(url);
  })
  .catch(error => console.log(error));
  };

  const handleSubmitUser = (e) => {
    e.preventDefault();
    const user = {
      name: newUserDistils.name,
      email: newUserDistils.email,
      password: newUserDistils.password,
      role: "user",
      companyName: CornetCompanyName,
      tutorialsAllowed: selectedUserTutorials,
    };
    console.log(user);
    dispatch(addUserAsync(user));
  };
  
  const handleSubmitFromFile = (e) => {
    e.preventDefault();
    const users = JSON.parse(usersFromFile);
    users.forEach((user) => {
      console.log(user);
      dispatch(
        addUserAsync({
          name: user.name,
          email: user.email,
          password: user.password,
          tutorialsAllowed: [{tutorialName: user.tutorialName, internships: user.interships.split(";")}],          
          role: "user",
          companyName: CornetCompanyName,
        })
      );
    });
  };

  //הגדרת הקובץ לפי הקובץ שנלחץ
  const filePathSet = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setFile(e.target.files[0]);
  };

  //קריאה לקובץ והעלאת הקובץ למערך
  const readFile = async () => {
    var f = file;
    const reader = new FileReader();
    reader.onload = async (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const a = convertToJson(data);
      setUsersFromFile(a);
    };
    reader.readAsBinaryString(f);
  };
  //הפיכת הקובץ לJSON
  const convertToJson = (csv) => {
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
      const obj = {};
      let currentline = lines[i].split(",");
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
    return JSON.stringify(result); //JSON
  };

  useEffect(() => {
    if (file != null) {
      readFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    setNumOfTutorials(numOfTutorials + 1);
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTutorials]);

  const addTutorialToUser = (e) => {
    e.preventDefault();
    const internships = selectInternshipValue.map((internship) => {
      return internship.value;
    });
    if (internships[0] === "") internships.shift();

    setSelectedUserTutorials([
      ...selectedUserTutorials,
      { tutorialName: selectTutorialValue.value, internships: internships, logData: [] },
    ]);
  };

  const handleSelectTutorial = (e) => {
    const tutorialsData = CornetCompanyData.templates;
    console.log(tutorialsData);

    // get interships from the selected tutorial
    const selectedTutorial = e.value;
    console.log(selectedTutorial);

    const selectedTutorialData = tutorialsData.find(
      (tutorial) => tutorial.Name === selectedTutorial
    );

    const selectedTutorialInternships =
      selectedTutorialData.internships === undefined
        ? []
        : selectedTutorialData.internships;
    console.log(selectedTutorialInternships);

    setSelectTutorialValue(selectedTutorial);
    setInternships(selectedTutorialInternships);
  };

  /* useEffect(() => {
    if (!showAddUser) return;
    //set value of name and email in the input
    console.log("newUserDistils", newUserDistils);
    //update the value of the input
    if (newUserDistils.name) {
      document.getElementById("Name").value = newUserDistils.name;
    }
    if (newUserDistils.email) {
      document.getElementById("myEmail").value = newUserDistils.email;
    }
    if (newUserDistils.password) {
      document.getElementById("Password").value = newUserDistils.password;
    }
  
    
    // if (showAddUser) setNewUserDistils({name:'', email:'', password:'', selectedTutorials:[]} );

  },[showAddUser,newUserDistils]); */
  useEffect(() => {
    if (!showAddUser) {
      return;
    }
  
    // update input values
    const nameInput = document.getElementById("Name");
    const emailInput = document.getElementById("myEmail");
    const passwordInput = document.getElementById("Password");
  
    if (newUserDistils.name) {
      nameInput.value = newUserDistils.name;
    }
    if (newUserDistils.email) {
      emailInput.value = newUserDistils.email;
    }
    if (newUserDistils.password) {
      passwordInput.value = newUserDistils.password;
    }
  
    // reset newUserDistils
    setNewUserDistils((prevState) => ({
      ...prevState,
      name: "",
      email: "",
      password: "",
      selectedTutorials: [],
    }));
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddUser]);
  return (
    <div className="main">
      <div className="register-block">
        <div className="register-block-header" onClick={() => setShowAddUser(!showAddUser)}>
          <h1>הוספת משתמש</h1>
          {!showAddUser ? <GoTriangleDown size={30} /> : <GoTriangleUp size={30} />}
        </div>
        {showAddUser ?
          <div className="sub-main">
            <UserFormControl 
            handleSubmit={handleSubmit} 
            selectInternshipValue={selectInternshipValue} 
            setSelectInternshipValue={setSelectInternshipValue} 
            selectTutorialValue={selectTutorialValue} 
            setSelectTutorialValue={setSelectTutorialValue} 
            selectedUserTutorials={selectedUserTutorials} 
            numOfTutorials={numOfTutorials} 
            selectTutorialOptions={selectTutorialOptions} 
            selectInternshipOptions={selectInternshipOptions} 
            labelStyle={labelStyle} 
            style={style} 
            optionStyle={optionStyle} 
            dropdownStyle={dropdownStyle} 
            handleSubmitUser={handleSubmitUser} 
            addTutorialToUser={addTutorialToUser} 
            handleSelectTutorial={handleSelectTutorial}  
            newUserDistils = {newUserDistils}
             setNewUserDistils = {setNewUserDistils}>           
            </UserFormControl>

            <form //form to control multiple users
              className="user-form-control"
              onSubmit={
                actionFileStatus === "createUser"
                  ? handleSubmitFromFile
                  : actionFileStatus === "download"
                    ? (e) => {
                      e.preventDefault();
                      handleDownloadButton(
                        "/employees/download/fileExample/",
                        "users.xlsx"
                      );
                    }
                    : null
              }
            >
              <input
                type="file"
                className="user-input"
                id="file"
                onChange={filePathSet}
                accept=".xls, .xlsx"
              />

              <button
                style={{ width: "60%" }}
                className="user-input"
                type="submit"
                onClick={async () => {
                  await readFile();
                  setActionFileStatus("createUser");
                }}
              >
                הוסף משתמשים
              </button>
              <button
                style={{ width: "50%" }}
                className="user-input"
                title="הורדת קובץ אקסל למילוי"
                onClick={() => setActionFileStatus("download")}
              >
                {/* ()=>handleDownloadButton('/employees/download/fileExample/',) }> */}
                הורד אקסל
              </button>
            </form>
          </div>
          : ''}


      </div>
    </div>
  );
};

export default Register;

function dropDownStyles() {
  const style = {
    // width: '30%',
    border: "1px solid #8F8F9D",
    borderRadius: "5px",
    marginBottom: "5%",
    maxWidth: "3rem",
  };

  const dropdownStyle = {
    border: "2px solid darkgray",
  };
  const labelStyle = {
    height: "2rem",
    overflow: "hidden",
  };

  const optionStyle = {
    padding: "0px",
    textAlign: "right",
  };
  return { labelStyle, style, optionStyle, dropdownStyle };
}

function dropdownValues(internships, tutorials) {
  let selectInternshipOptions = internships.map((internship) => {
    return {
      value: internship,
      label: internship,
    };
  });
  selectInternshipOptions.unshift({ label: "הכל", value: "" });

  let selectTutorialOptions =
    tutorials === undefined || tutorials === null || tutorials === ""
      ? [{ value: "", label: "" }]
      : tutorials.map((tutorial) => {
        return {
          value: tutorial,
          label: tutorial,
        };
      });
  return { selectTutorialOptions, selectInternshipOptions };
}

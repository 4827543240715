// import React from 'react'

const Drawer2 = ({handleStartTutorialSubmit,TutorialNames}) => {
  return (
    <form
    onSubmit={handleStartTutorialSubmit}
    style={{
      margin: "0 auto",
      paddingBottom: "1rem",
      borderBottom: "1px solid gray",
    }}
  >
    <div style={{display: "flex", flexDirection: "column"}}>
      <label htmlFor="selectTutorial">בחר הדרכה</label>
      <select
        name="selectTutorial"
        id="selectTutorial"
        style={{ width: "100%" }}
      >
        {TutorialNames?.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
    <input
      style={{ marginTop: "1rem" }}
      type="submit"
      value="הפעל הדרכה"
    />
  </form>
  )
}

export default Drawer2
import CompanyBlock from './components/CompanyBlock';
import { useSelector } from 'react-redux';

function CostumersPage() {
  const companies = useSelector((state) => state.companies.AllCompanies);
  const filterCompanies = companies.filter((company) => company.visible !== false);
  console.log(filterCompanies);
  const companyBlocks = filterCompanies.map((company, index) => (
    <CompanyBlock key={index} company={company} />
  ));

  return (
    <div style={{ margin: '1%', position: 'relative' }}>
      <h1>לקוחות</h1>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '22% 22% 22% 22%',
          gap: '2%',
        }}
      >
        {companyBlocks}
      </div>
      <div style={{ position: 'absolute', bottom: -400, left: 0 }}>
        <button
          style={{
            backgroundColor: '#4CAF50',
            border: 'none',
            color: 'white',
            padding: '15px 32px',
            textAlign: 'center',
            textDecoration: 'none',
            display: 'inline-block',
            fontSize: '16px',
          }}
        >
          הוסף לקוח
        </button>
      </div>
    </div>
  );
}

export default CostumersPage;
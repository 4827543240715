import { useEffect, useState } from 'react';
import StylingMenu from '../StylingMenu';

const LinkBlock = (props) => {
  const {
    index1,
    element,
    textItem,
    style,
    color,
    isElementSelectedProp,
    contenteditable,
    handleLinkInput,
    handleStyleChange,
    selectedElement,
    setIsElementSelected,
    setSelectedElement,
    setIsListElementSelected,
    isListElementSelected,
    handleDeleteElement,
    showLink,
    setShowLink,
    dragOver,
    dragStarted,
    body,
    dragDropped,


  } = props;

  const [text, setText] = useState(textItem);
  const [src, setSrc] = useState('');
 

  useEffect(() => {
    if (text !== '') {
      handleLinkInput(index1, 'text', text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text,index1]);

  useEffect(() => {
    if (src !== '') {
      handleLinkInput(index1, 'src', src);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src,index1]);

  return (
    <>
      {contenteditable && isElementSelectedProp && !isListElementSelected && (
        <StylingMenu
          handleStyleChange={handleStyleChange}
          selectedElement={selectedElement}
          setIsElementSelected={setIsElementSelected}
          setSelectedElement={setSelectedElement}
          setIsListElementSelected={setIsListElementSelected}
          handleDeleteElement={handleDeleteElement}
          elementId={`Link-${index1}`}
          elementType={'link'}
          setShowLink={setShowLink}
          showLink={showLink}

          
        />
      )}      
      {contenteditable && showLink ? (
      <a id={`Link-${index1}`} key={`link-${index1}`} href={element.src}
      onDragOver={(e) => dragOver(e, index1)}
      onDragStart={(e) => dragStarted(e, index1)}
      onFocus={() => {
        setIsListElementSelected(body[index1].type === 'uoList' || body[index1].type === 'oList');
        setSelectedElement({ index1, index2:null });
        setIsElementSelected(true);
      }}
      >
        {element.text || element.src || 'link'} </a>) : (<div
        draggable={contenteditable}
        onDrop = {dragDropped}
        onDragOver={(e) => dragOver(e, index1)}
        onDragStart={(e) => dragStarted(e, index1)}
        onFocus={() => {
          setIsListElementSelected(body[index1].type === 'uoList' || body[index1].type === 'oList');
          setSelectedElement({ index1, index2:null });
          setIsElementSelected(true);
        }}
        style={{ width: '80%', marginRight: '2rem' }}>
          <div style={{ display: 'grid', gridTemplateColumns: 'max-content 1fr', gap: '1rem', alignItems: 'center' }}>
            <div style={{ height: '100%' }}>
              <div style={{ display: 'flex', marginBottom: '1rem' }}>
                <label htmlFor={`textLink-${index1}`}>טקסט:</label>
                <input
                  id={`Link-${index1}`}
                  type="text"
                  value={text}
                  style={{ ...style, color, width:'max-content',height:'min-content'}}
                  onInput={(e) => setText(e.target.value)}
                  onFocus={() => {
                    setIsListElementSelected(false);
                    setSelectedElement({ index1, index2: null });
                    setIsElementSelected(true);
                  }}
                />
              </div>
              
              <div style={{ display: 'flex' }}>
                <label htmlFor={`srcLink-${index1}`}>כתובת:</label>
                <input
                  type="text"
                  id={`srcLink-${index1}`}
                  value={src}
                  style={{ ...style, color, width: '100%' }}
                  onInput={(e) => setSrc(e.target.value)}
                  onFocus={() => {
                    setIsListElementSelected(false);
                    setSelectedElement({ index1, index2: null });
                    setIsElementSelected(true);
                  }}
                />
              </div>
             
            </div>
          </div>
        </div>
      ) }
    </>
  );
};

export default LinkBlock;

// FILE NAME: TutorialsForUser.jsx
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SmartSelect from "react-smart-select";
import { useForm } from "react-hook-form";
import BoxAria from "../../register/BoxAria";

const TutorialsForUser = (props) => {
  const { user, editMode, setNewUserDistils, newUserDistils } = props;
  const { labelStyle, style, optionStyle, dropdownStyle } = dropDownStyles();
  const [selectedUserTutorials, setSelectedUserTutorials] = useState([]);
  const [numOfTutorials, setNumOfTutorials] = useState(0);
  const [selectInternshipValue, setSelectInternshipValue] = useState();
  const [selectTutorialValue, setSelectTutorialValue] = useState();
  const [internships, setInternships] = useState(["a", "b"]);
  const { handleSubmit } = useForm();

  const tutorials = useSelector(
    ({ companies }) => companies.TutorialsNamesForCurrentCompany
  );

  const CornetCompanyData = useSelector(
    ({ companies }) => companies.CornetCompanyData
  );

  useEffect(() => {
    setNumOfTutorials(numOfTutorials + 1);
    setNewUserDistils({ ...newUserDistils, selectedTutorials: selectedUserTutorials });
    // console.log("selectedUserTutorials", selectedUserTutorials);
    // eslint-disable-next-line  
  }, [selectedUserTutorials]);

  let { selectTutorialOptions, selectInternshipOptions } = dropdownValues(
    internships,
    tutorials
  );
  const addTutorialToUser = (e) => {
    e.preventDefault();
    const internships = selectInternshipValue.map((internship) => {
      return internship.value;
    });
    if (internships[0] === "") internships.shift();

    setSelectedUserTutorials([
      ...selectedUserTutorials,
      {
        tutorialName: selectTutorialValue.value,
        internships: internships,
        logData: [],
      },
    ]);
  };

  const handleSelectTutorial = (e) => {
    const tutorialsData = CornetCompanyData.templates;
    // get interships from the selected tutorial
    const selectedTutorial = e.value;
    const selectedTutorialData = tutorialsData.find(
      (tutorial) => tutorial.Name === selectedTutorial
    );

    const selectedTutorialInternships =
      selectedTutorialData.internships === undefined
        ? []
        : selectedTutorialData.internships;

    setSelectTutorialValue(selectedTutorial);
    setInternships(selectedTutorialInternships);
  };

  return (
    <div>
      {editMode ? (
        <form onSubmit={(e) => handleSubmit(addTutorialToUser(e))}>
          <div className="user-form-control">
            <div className="dropdown">
              <label className="dropdown-label" style={labelStyle}>
                הדרכות רצויות
              </label>

              <SmartSelect
                value={selectTutorialValue}
                options={selectTutorialOptions}
                style={style}
                optionStyle={optionStyle}
                dropdownStyle={dropdownStyle}
                onChange={(e) => {
                  handleSelectTutorial(e);
                  return setSelectTutorialValue(e);
                }}
                labelStyle={labelStyle}
              />
            </div>
            <div className="dropdown">
              <label className="dropdown-label" style={labelStyle}>
                התמחויות רצויות
              </label>
              <SmartSelect
                value={selectInternshipValue}
                options={selectInternshipOptions}
                style={style}
                optionStyle={optionStyle}
                dropdownStyle={dropdownStyle}
                onChange={setSelectInternshipValue}
                multi
                toggle
                labelStyle={labelStyle}
              />
            </div>
            <input
              type="submit"
              style={{
                display: "flex",
                alignSelf: "end",
                marginBottom: "2%",
              }}
              className="user-input"
              value="הוסף הדרכה"
            />
          </div>
          <BoxAria
            numOfTutorials={numOfTutorials}
            selectedUserTutorials={selectedUserTutorials}
          />
        </form>
      ) : (
        <div>
          {user?.internships?.map((item,index) => {
            return (
              <p key={index} style={{ marginTop: 0, marginBottom: 0 }}>
                <b>הדרכה:</b> {item.tutorialName} <b>התמחות:</b>
                {item.internships}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TutorialsForUser;

function dropDownStyles() {
  const style = {
    // width: '30%',
    border: "1px solid #8F8F9D",
    borderRadius: "5px",
    marginBottom: "5%",
    maxWidth: "3rem",
  };

  const dropdownStyle = {
    border: "2px solid darkgray",
  };
  const labelStyle = {
    height: "2rem",
    overflow: "hidden",
  };

  const optionStyle = {
    padding: "0px",
    textAlign: "right",
  };
  return { labelStyle, style, optionStyle, dropdownStyle };
}

function dropdownValues(internships, tutorials) {
  let selectInternshipOptions = internships.map((internship) => {
    return {
      value: internship,
      label: internship,
    };
  });
  selectInternshipOptions.unshift({ label: "הכל", value: "" });

  let selectTutorialOptions =
    tutorials === undefined || tutorials === null || tutorials === ""
      ? [{ value: "", label: "" }]
      : tutorials.map((tutorial) => {
        return {
          value: tutorial,
          label: tutorial,
        };
      });
  return { selectTutorialOptions, selectInternshipOptions };
}

import useContextMenu from "../../../Hooks/useContextMenu";
import useSelectedText from "../../../Hooks/useSelectedText";
import { useDispatch } from "react-redux";
import { setInternships, 
  // removeInternship
} from "../../../store/slices/tutorialSlice";

import { useState } from "react";
const ContextMenu = () => { 
  const dispatch = useDispatch();
  const [anchorPoint, show] = useContextMenu("mainBody");
  const [getList] = useSelectedText();
  const [theNewList, setTheNewList] = useState([]);
  
  const handleSaveInternshipsToStore = () => {
    dispatch(setInternships(theNewList));
  };

  const HandleSaveInternships = () => {
    const a = getList;
    setTheNewList([...theNewList, a]);
  };

  const handleRemoveItem = (item) => {
    const newList = theNewList.filter((tItem) => tItem !== item);
    setTheNewList(newList);
  };

  // useEffect(() => {
   
  // }, [theNewList]);

  if (show) {
    return (
      <ul className="menu" style={{ top: anchorPoint.y-100, left: anchorPoint.x-100 ,zIndex:"9999"}}>
        <li>עובד בדפדפן כרום בלבד</li>
        <hr />
        <li
          className="selected"
          onClick={() => {           
            HandleSaveInternships();
          }}
        >
          הוסף את ההתמחות
        </li>
        <li className="selected" onClick={handleSaveInternshipsToStore}>
          שמור את ההתמחויות
        </li>
        <hr />
        <li>ההתמחויות שנוספו:</li>
        <hr />
        {theNewList?.map((item, index) => (
          <li key={index} onClick={() => handleRemoveItem(item)}>
            {item}
          </li>
        ))}
      </ul>
    );
  }
  return <div></div>;
};

export default ContextMenu;

import React from "react";
import GaugeChart from "react-gauge-chart";
import "./card.css";

const Card = ({ index,header,percent, colors}) => {
  return (
    <div className='cardStyle'>
      <h2 style={{ textAlign: "center"}}>{header}</h2>
      <GaugeChart
        id={"GaugeChart" + index}     
        colors={colors}
        arcsLength={[0.3, 0.5, 0.2]}
        arcWidth={0.1}
        arcPadding={0.02}
        percent={percent}
        textColor="#000000"
        needleBaseColor="black"
        needleColor="red"
        hideText ={false}
      />
    </div>
  );
};

export default Card;

import { createSlice } from '@reduxjs/toolkit';

const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState: {
    templateName: null,
    internships: null,
    tutorialSlides: null,
    answerArchive: null,
    contentPage: null,
    slideNumber: 0,

    statistics: {
      numberOfQuestions: 0,
      numberOfCorrectAnswers: 0,
      generalSuccess: 0,
      percentSuccess: 0,
    },
    
  },
  reducers: {
    incrementNumberOfCorrectAnswers: (state) => {
      state.statistics.numberOfCorrectAnswers =
        state.statistics.numberOfCorrectAnswers + 1;
      state.statistics.generalSuccess =
        (state.statistics.numberOfCorrectAnswers /
          state.statistics.numberOfQuestions) *
        100;  
      const wrightAnswers = state.answerArchive.theAnswers
        .map((answer) => answer.correct)
        .filter((answer) => answer === true).length;
      const AllTries = state.answerArchive.theAnswers.map(
        (answer) => answer.correct
      ).length;
      state.statistics.percentSuccess = (wrightAnswers / AllTries) * 100;
    },

    setNumberOfQuestions: (state) => {
      if (!state.tutorialSlides) return;
      state.statistics.numberOfQuestions = state?.tutorialSlides
        .map((slide) => slide.body.map((a) => a.type))
        .filter((type) => type[0] === 'quiz')
        .map((a) => a[0]).length;
    },
    setAnswerArchive: (state, { payload }) => {
      state.answerArchive = payload;
    },
    addAnswerToArchive: (state, { payload }) => {
      // state.answerArchive = [...state.answerArchive,payload];
      state.answerArchive.theAnswers = [
        ...state.answerArchive.theAnswers,
        payload,
      ];
    },

    setTemplateName: (state, { payload }) => {
      state.templateName = payload;
    },
    setContentPage: (state, { payload }) => {
      state.contentPage = payload;
    },
    setInternships: (state, { payload }) => {
      state.internships = payload;
    },
    removeInternship: (state, { payload }) => {
      state.internships = state.internships.filter(
        (internship) => internship.id !== payload
      );
    },

    setTutorial: (state, { payload }) => {
      state.tutorialSlides = payload;
    },

    addPageToTutorial: (state, { payload }) => {
      const newArray = [...state.tutorialSlides];
      newArray.splice(payload+1,0,  {
        header: {
          h1: [''],
        },
        chapter: '',
        pageStyle: {padding: '1%',width: '90%',height: '90%',margin: 'auto'},
        body: [
          
        ],
      });

      state.tutorialSlides = newArray;
    },

    removePageFromTutorial: (state, { payload }) => {
      const newArray = [...state.tutorialSlides];
      newArray.splice(state.slideNumber, 1);
      state.tutorialSlides = newArray;
    },
    setCurrentSlideNumber: (state, { payload }) => {
      state.slideNumber = payload;
    },
  },
});

export const {
  removePageFromTutorial,
  setCurrentSlideNumber,
  setTemplateName,
  setContentPage,
  setTutorial,
  addPageToTutorial,
  setInternships,
  removeInternship,
  setAnswerArchive,
  addAnswerToArchive,
  setNumberOfQuestions,
  incrementNumberOfCorrectAnswers,
} = tutorialSlice.actions;

export default tutorialSlice.reducer;


import { setTemplateName } from "../../../../store/slices/tutorialSlice"
import { Form } from "antd";
import { useDispatch } from "react-redux";

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';



import CloseIcon from '@mui/icons-material/Close';
import { axiosPrivate } from '../../../../apis/axiosPrivate';


import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import Drawer1 from "./Drawer1";
import Drawer2 from "./Drawer2";


const TopMenu = ({ list, children, menuItems, isInTemplateEditor, typography, handleStartTutorialSubmit, TutorialNames }) => {

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ flexGrow: 1 }} >
      <AppBar position="static" id="AppBar" sx={{marginBottom:2}}>
        <Toolbar>
          {/* <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton> */}
          <IconButton
            style={{ color: 'white', marginLeft: '10px' }}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"

          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="h1" sx={{ flexGrow: 1, color: 'white' }}>
            {/* תפריט ניהול */}
            {typography}
          </Typography>


          {isInTemplateEditor ? <Form style={{ flexGrow: 1, display: "flex" }}>

            <Typography variant="h5" noWrap component="h2" sx={{ color: 'white' }}>
              שם תבנית ההדרכה:
            </Typography>
            <input
              type="text"
              placeholder="רשום שם לתבנית ההדרכה"
              onChange={(e) => dispatch(setTemplateName(e.target.value))}
              style={{
                height: "1.3rem",
                alignSelf: "center",
                border: "none",
                marginRight: "40px",
                backgroundColor: "#3F51B5",
                color: "white",
              }}
            />
          </Form> : null}
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} /> */}


          {/* <Button variant="contained" color="primary" href="/" onClick={() =>{
      axiosPrivate.get('/logout')

    }}sx={{fontSize:18}}> התנתק</Button> */}

          <IconButton color="inherit" aria-label="logout" href="/"
            onClick={() => {
              axiosPrivate.get('/logout')
            }}>
            <Typography variant="body1" sx={{ fontSize: 16, fontWeight: "bold" }}>
              התנתק
            </Typography>
          </IconButton>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="right"
        open={open}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
          },
        }}
      >

        <IconButton style={{ width: "2rem" }} onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>

        <Divider />

        <Divider />
        {list ? <Drawer1 menuItems={menuItems} handleDrawerClose={handleDrawerClose} /> :
          <Drawer2 handleStartTutorialSubmit={handleStartTutorialSubmit} TutorialNames={TutorialNames} />}
      </Drawer>
      <main   >
        <div />

        {children}

      </main>
    </Box>

  )
}

export default TopMenu
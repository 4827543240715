import React from 'react'

import './menuControl.css'

const MenuButton = (props) => {
    const {buttonSettings,showText=false,showTitle=true} = props
  return (
    <button className={showText?"menu-button":"menu-button-no-text"} onClick={()=>props.onClick(buttonSettings.toAdd)} title={showTitle?buttonSettings.alt:null}>       
        <img src={buttonSettings.src} alt={buttonSettings.alt} />
       { showText&& <p className="menu-button__line">{buttonSettings.alt}</p>}
       
    </button>
  )
}

export default MenuButton
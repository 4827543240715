import Register from './../components/general/register/Register';
import UsersView from '../components/general/UsersView/UsersView';

const Users = () => {
  return (
    <div>
        <Register/>
        <UsersView/>
    </div>
  )
}

export default Users
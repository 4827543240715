import React, { useEffect } from 'react';

const MainImageBg = ({ children }) => {
  function importAll(r) {
    try {
      let images = {};
      r.keys().forEach((item) => {
        const key = item.replace('../', ''); // or customize the key as needed
        images[key] = r(item);
      });
      return images;
    } catch (error) {
      console.error("Error in importAll: " + error.message);
      return {}; // Return an empty object to prevent issues with undefined values
    }
  }
  

  const [picNumber, setPicNumber] = React.useState(0);
  const [pic, setPic] = React.useState('');

  const images = importAll(
    require.context('../assetes/images/background', false, /\.jpg/)
  );

  useEffect(
    () => {
      try {
        setPicNumber(
          parseInt(Math.random(0, 10) * (Object.keys(images).length - 1))
        );

        setPic(images[Object.keys(images)[picNumber]]);
      } catch (error) {
        console.error(error);
      }
    },

    // setPicNumber(parseInt(Math.random(0, 10) * (images['0.png'].length - 1)));
// eslint-disable-next-line
    [picNumber]
  );

  // const bgArray = [bg1, bg2, bg3, bg4, bg5];
  return (
    <div
      className="main-image-bg"
      style={{
        backgroundImage: `url("${pic}")`,
      }}
    >
      {children}
    </div>
  );
};

export default MainImageBg;

import { useEffect, useState, useRef } from 'react';
import Card from './Card';
import { useSelector } from 'react-redux';

const GaugesPage = () => {
  const Companies = useSelector(({ companies }) => companies?.AllCompanies);
  const targetOfActiveCompanies = 10;
  // const numberOfActiveTutorials = 10;

  const [numberOfActiveCompanies, setNumberOfActiveCompanies] = useState(0);
  const [totalNumberOfCompanies, setTotalNumberOfCompanies] = useState(0);

  const [activePercent1, setActivePercent] = useState(0);
  const [targetPercent, setTargetPercent] = useState(0);

  // get from stor using Companies the number Of Companies with status Active and call it numberOfActiveCompanies


  const callOnce = useRef(false);
  callOnce.current = false;
  // const renderAfterCalled = useRef(false);

  useEffect(() => {
    setTargetPercent(numberOfActiveCompanies / targetOfActiveCompanies);
  }, [numberOfActiveCompanies]);

  useEffect(() => {
    if (callOnce.current) return;

    const activeCompanies = Companies?.filter(
      (company) => company?.status === 'active'
    );

    setNumberOfActiveCompanies(activeCompanies?.length || 0);

    setTotalNumberOfCompanies(Companies?.length || 0);

    setActivePercent((activeCompanies?.length / Companies?.length) || 0);

    callOnce.current = true;

    // return () => {
    //   if (!renderAfterCalled.current) {
    //     return;
    //   }
    // };
  }, [Companies, numberOfActiveCompanies, totalNumberOfCompanies]);

  // const activePercent1 = 0.37;
  // const targetPercent = 0.5;
  const targetAverageOfActiveTutorialsPercent = 0.5;
  return (
    <div className="GaugesPage">
      <div className="GaugesPage__header">
        <h1>סטטיסטיקות</h1>
      </div>
      <div className="layoutStyle">
      {/* מספר החברות הפעילות לחלק למספר החברות במערכת */}
      <Card index={1} header={'אחוז חברות פעילות'} percent={activePercent1} colors ={[ "#EA4228","#F5CD19","#5BE12C"]} />
      {/* מספר החברות הפעילות לחלק ליעד*/}
      <Card index={1} header={"מס' הלקוחות מול היעד"} percent={targetPercent} colors ={[ "#EA4228","#F5CD19","#5BE12C"]}/>
      {/* ממוצע התבניות לחברה פעילה - סך התבניות של החברות הפעילךות לחלק למספר חברות פעילות*/}
      <Card
        index={1}
        header={'מס תבניות ממוצע לחברה'}
        percent={targetAverageOfActiveTutorialsPercent}
        colors ={[ "#EA4228","#F5CD19","#5BE12C"]}
      />
    </div>
      </div>
  );
};

export default GaugesPage;

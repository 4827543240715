import axios from 'axios';
import { settingData } from '../config/settings';
import { memoizedRefreshToken } from './refreshToken';

const { remoteHost, localHost, mode } = settingData;
const baseURL = mode === 'production' ? remoteHost : localHost;

const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(async (config) => {
  try {
    const cookies = document.cookie;
    let session = undefined;

    if (cookies) {
      session = cookies
        ?.split(';')
        .find((item) => item?.includes('Authorization'))
        ?.split('=')[1];
    } else {
      session = config.headers?.authorization?.split(' ')[1];
    }

    if (session) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${session}`,
      };
    }

    return config;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    try {
      const config = error?.config;

      if (error?.response?.status === 401 && !config?.sent) {
        config.sent = true;

        const result = await memoizedRefreshToken();
        document.cookie = `Authorization=${result}; path=/; SameSite=None; secure=true`;

        if (result) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${result}`,
          };
        }

        return axiosInstance(config);
      }
      return Promise.reject(error);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const axiosPrivate = axiosInstance;


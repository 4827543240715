import { configureStore } from '@reduxjs/toolkit';
import companyReducer from './slices/companySlice';
import usersReducer from './slices/usersSlice';
import tutorialReducer from './slices/tutorialSlice';
// import internships from './slices/internshipsSlice';


export const store = configureStore({
  reducer: {
    companies: companyReducer,
    users: usersReducer,
    tutorial: tutorialReducer,
    // internships: internships,
  },
});

import React from "react";

const Error = () => {
    const styles = {
        container: {
            // background: `repeating-linear-gradient(-45deg, #ffffffc4 1px, #ffffffd2 1px, #ff0000 2px, #ff0000 12px)`,
            display:"grid",
            placeItems: "center",
            height: "100%",
            width: "100%",
           
        },
    }
  return <h1 style={styles.container}>ארעה שגיאה הדף לא נמצא...</h1>;
};

export default Error;

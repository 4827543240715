import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import TextWithHeader from "../components/TextWithHeader";
import MainImageBg from "../components/MainImageBg";
import TextToSpeech from "aiava-text-to-speech";


const TutorialPage = ({
  DefaultData,
  answer,
  setAnswer,
  configuration,
  speech,
  slidDirection,
  currentPage,
  setCurrentPage,
  allowNextPage,
  setAllowNextPage,
  specificInternship,
  setSpecificInternship,
  contenteditable,
  size
}) => {
  const [pageData, setPageData] = useState(DefaultData);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const speaker = new TextToSpeech();
  speaker.language = "iw";


  useEffect(() => {
    setPageData(DefaultData);
  }, [DefaultData]);

  useEffect(() => {
    const a = pageData?.header?.h1?.join(",");
    const  speaker1 = speaker.speaker;
    let b;
    try {
      b = pageData?.body[0]?.text?.join(",");
    } catch (e) {
      b = "";
    }
    if (b === "" || b === undefined) {
            b = pageData?.body[0]?.body?.Question + " בחר וסמן את התשובה הנכונה לדעתך " + pageData?.body[0]?.body?.items.join(",");
    }
    if (speech) {
      try {
        speaker1.cancel();       
        speaker.say(a + " " + b);
      } catch (e) {
        console.error("Error: ", e);
      }
    } else {
      speaker1.cancel();
    }
    // eslint-disable-next-line
  }, [speech, pageData]);

  const myX = slidDirection === "rtl" ? -1000 : 1000;

  return (
    <motion.div
      initial={{ x: myX, opacity: 0 }}
      animate={{ zIndex: 1, x: 0, opacity: 1 }}
      exit={{ zIndex: 0, x: myX, opacity: 0, transition: { duration: 0.5 } }}
      className="mainBody"
      id={size ==="mini" ? "mini-mainBody" : "mainBody"}
    >
      <MainImageBg>
        <div
          className="content-right"
  
        >
          <TextWithHeader
            setPageData={setPageData}
            pageData={pageData ? pageData : null}
            setDragOverIndex={setDragOverIndex}
            header={pageData?.header}
            body={pageData?.body}
            router={
              pageData?.router
                ? pageData?.router
                : { pageType: "regular", nextPage: "", pageRoutes: "" }
            }
            pageStyle={pageData?.pageStyle || null}
            answer={answer}
            setAnswer={setAnswer}
            configuration={configuration}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            allowNextPage={allowNextPage}
            setAllowNextPage={setAllowNextPage}
            specificInternship={specificInternship}
            setSpecificInternship={setSpecificInternship}
            contenteditable={contenteditable}
            size={size}   
            dragOverIndex={dragOverIndex}         
            
          />
        </div>

        <div
          className="content-left"
          style={answer.visible === false ? { display: "none" } : null}
        >
          <div className="content-left-inner">
            {answer.visible === true ? (
              <div className={answer.correct ? "correct" : "incorrect"}>
                 {pageData?.body[0]?.body?.Question || ''} {/* Ensure proper null/undefined check */}
                {answer.text}
              </div>
            ) : null}
          </div>
                  
        </div>
      </MainImageBg>
    </motion.div>
  );
};

export default TutorialPage;
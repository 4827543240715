//  FILE NAME: TextBoxCell.jsx
import React from "react";

const TextBoxCell = (props) => {
  const { index, fieldName, data, editMode,setNewUserDistils,newUserDistils } = props;
  // name:'',email:'',intership:[]
  return (
    <div className="cell">
      {editMode ? (
        <input type="text" id={index} name={fieldName} placeholder={data} onChange={
          (e) => {
            setNewUserDistils({ ...newUserDistils, [fieldName]: e.target.value });
          }
        }></input> 
      ) : (
        <div className="users-table-cell">{data}</div>
      )}
    </div>
  );
};

export default TextBoxCell;

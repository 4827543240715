import React from 'react'

const Header = ({numberOfPagesColor,currentPage,maxPage,text,headerSquareColor}) => {
  return (
    <div className="header">
    <div className="header-content" style={{backgroundColor:headerSquareColor}}>
      {text.map((text,index) =><h1 key={index}>{text}</h1>)}
    </div>
    <div style={{backgroundColor:`${numberOfPagesColor?numberOfPagesColor:''}`}} className="shkufiot">
      <p style={{ margin: "1rem 1rem 0 0" }}>
        עמוד {currentPage + 1}
      </p>
      <p style={{ margin: "0rem 1rem 0 0" }}>
      מתוך {maxPage}
      </p>

    </div>
    </div>
  )
}

export default Header
import React, { Suspense } from "react";
// import TutorialPage from "../pages/tutorialPage";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

const AnimatedRoutes = ({
  currentPage,
  setCurrentPage,
  slidDirection,
  configuration,
  DefaultData,
  answer,
  setAnswer,
  speech,
  setSpeech,
  allowNextPage,
  setAllowNextPage,
  specificInternship,
  setSpecificInternship,
  handlers
}) => {
  const location = useLocation();

  
  const TutorialPage = React.lazy(() => import("../pages/tutorialPage"));
  return (
    <AnimatePresence initial={false} exitBeforeEnter>
       <div  className="mainBody" {...handlers}>
      <Routes location={location} key={location.pathname}>
      <Route
    key={747}
      path="/*"
      element={
        <Suspense fallback={<div>Loading...</div>}>          
        <TutorialPage
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          slidDirection={slidDirection}
          configuration={configuration}
          DefaultData={DefaultData}
          answer={answer}
          setAnswer={setAnswer}
          speech={speech}
          setSpeech={setSpeech}
          allowNextPage={allowNextPage}
          setAllowNextPage={setAllowNextPage}
          specificInternship={specificInternship}
          setSpecificInternship={setSpecificInternship}
        />
        </Suspense>
      }
    />
      </Routes>
       </div>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;

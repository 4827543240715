//UserDataTamplet-rc-table.jsx
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomExpandIcon from "./CustomExpandIcon";
import Table from "rc-table";


import "./index.css";

import { fetchUsersByCompany} from "../../../store/slices/usersSlice";
// import { setCornetCompany } from "../../../../store/slices/companySlice";

const UserDataTamplet = () => {
    const dispatch = useDispatch();
    //pull all users and CornetUser from DB
    const usersDB = useSelector(({ users }) => users.AllUsers);
    const CornetUser = useSelector(({ users }) => users.CornetUser);
    
    //pull Cornet Company  by role from DB
    useEffect(() => {
      const role = CornetUser.role;
      const CornetCompany = CornetUser.companyName;   
      dispatch(fetchUsersByCompany(CornetCompany, role));
    }, [dispatch,CornetUser.companyName,CornetUser.role]);
  
  
  // maping the data from DB to the table
    const dataList = usersDB?.map((user, index) => {    //   
      // console.log("tutorialsAllowed",user.tutorialsAllowed);
      return { 
        key: index, name: user?.name, email: user?.email,  dipartment:"עתידי",   
        statictis:[{
         Key: `subIndex-${index}`,
          tutorial:user.tutorialsAllowed?.map((t)=>t.tutorialName).flat()?.[0],  
          generalSuccess:user.tutorialsAllowed?.map((t)=>t.logData.map((l)=>l.generalSuccess)).flat()?.[0],
          numberOfCorrectAnswers:user.tutorialsAllowed?.map((t)=>t.logData.map((l)=>l.numberOfCorrectAnswers)).flat()?.[0],
          numberOfQuestions:user.tutorialsAllowed?.map((t)=>t.logData.map((l)=>l.numberOfQuestions)).flat()?.[0],
          percentSuccess:user.tutorialsAllowed?.map((t)=>t.logData.map((l)=>l.percentSuccess)).flat()?.[0]?.toFixed(2)+"%",        
        }]       
      };
    });
    const columns = [
      { title: "מס", dataIndex: "key", key: "key", width: "max-content" },
      { title: "שם", dataIndex: "name", key: "name", width: "max-content" },
      { title: "מייל", dataIndex: "email", key: "email", width: "max-content" },
      { title: "מחלקה", dataIndex: "dipartment", key: "dipartment", width: "max-content" }
    ];
    const subColumns = [
      { title: "שם הדרכה", dataIndex: "tutorial", key: "tutorial", width: 100 },
      { title: "אחוזי ביצוע", dataIndex: "generalSuccess", key: "generalSuccess", width: 200 },
      { title: "מס תשובות נכונות", dataIndex: "numberOfCorrectAnswers", key: "numberOfCorrectAnswers", width: 200 },
      { title: "אחוזי הצלחה", dataIndex: "percentSuccess", key: "percentSuccess", width: 200 }
    ];

    const onExpand = (expanded, record) => {
      // eslint-disable-next-line no-console
      console.log("onExpand", expanded, record);
    };

  return (
    <div className="users-block">
    <div className="main-block">
      <h1>נתוני הדרכות</h1>
      <Table
      style={{ width: "90%" , margin:"auto"}}
      columns={columns}
      data={dataList}
      expandable={{
      expandRowByClick: true,
      expandedRowRender: record => <Table key={'ff'} columns={subColumns} data={record.statictis} />,     
      onExpand,
      expandIcon: CustomExpandIcon
    }}
  />
    </div>
  </div>
  )
}

export default UserDataTamplet
// FILE NAME: TableLine.jsx
import React, { useState } from "react";
import {axiosPrivate} from "../../../../../apis/axiosPrivate";
import useToggle from "@rooks/use-toggle";
import TextBoxCell from "./cell/TextBoxCell";

import TutorialsForUser from "./TutorialsForUser";

const TableLine = (props) => {
  const { index, user, dispatch, deleteUsersAsync, updateUserAsync } = props;
  const [newPassword, setNewPassword] = useState(null);
  const [newUserDistils, setNewUserDistils] = useState({});
  const [showHidePassword, setShowHidePassword] = useToggle(true);

  const [editMode, setEditMode] = useToggle(false);

  const handleResetPassword = async () => {
    await axiosPrivate
      .put(`/employees/resetPassword/${user.email}`)
      .then((res) => setNewPassword(res.data.newPassword));
  };

  return (
    <div className="users-table-row" key={index}>
      <TextBoxCell
        index={index + 1}
        fieldName="name"
        data={user.name}
        editMode={editMode}
        setNewUserDistils={setNewUserDistils}
        newUserDistils={newUserDistils}
      />
      <TextBoxCell
        index={index + 2}
        fieldName="email"
        data={user.email}
        editMode={editMode}
        setNewUserDistils={setNewUserDistils}
        newUserDistils={newUserDistils}
      />

      <TutorialsForUser
        index={index}
        user={user}
        editMode={editMode}
        setNewUserDistils={setNewUserDistils}
        newUserDistils={newUserDistils}
      />

      {/* <div className="users-table-cell">{user.intership}</div> */}

      <div className="users-table-cell actions">
        <div className="users-table-cell-buttons">
          <button
            className="users-table-cell-button"
            onClick={() => {
              if (showHidePassword === true) {
                handleResetPassword();
              } else {
                setNewPassword(null);
              }
              setShowHidePassword();
            }}
          >
            {showHidePassword ? "אפס" : "הסתר סיסמא"}
          </button>
          <button
            className="users-table-cell-button"
            onClick={() => {
              console.log(newUserDistils);
              const fullUser = { ...user, newUserDistils };
              console.log('fullUser: ',fullUser);
              if (editMode) {
                dispatch(updateUserAsync(fullUser));
              }
              setEditMode();
            }}
          >
            עדכן
          </button>
          <button
            className="users-table-cell-button"
            onClick={() => {
              try {
                dispatch(deleteUsersAsync(user.email));
              } catch (error) {
                console.log(error);
              }
            }}
          >
            מחק
          </button>
        </div>
        {newPassword ? <p>הסיסמא החדשה: {newPassword}</p> : null}
      </div>
    </div>
  );
};

export default TableLine;

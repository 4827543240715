import { useState } from 'react';
// import { BiLayerPlus, BiCloudDownload, BiCloudUpload, BiDownload, BiLoaderAlt, BiLoader, BiUpload,BiTrash } from 'react-icons/bi';
import { BiLayerPlus,  BiUpload,BiTrash } from 'react-icons/bi';
import { RiCloseCircleLine } from 'react-icons/ri';
import axiosFileUpload from '../../../apis/axios';
import axios from '../../../apis/axios';
import ExplorerWindow from '../../admin/components/upDwonGallery/explorer';


const MediaMenu = (props) => {  
  // dragStarted,
  // dragOver,
  
const {handleAddSrc,index,handleSubmitGallery, PIndex,setIsElementSelected,fileType='image',handleDeleteElement,selectedElement,setSelectedElement} = props;
const [selectedImageName, setSelectedImageName] = useState('');

const handleFileUpload = (event) => {
  const files = Array.from(event.target.files);

  const formData = new FormData();
  files.forEach((file) => {
    const encodedFileName = encodeURIComponent(file.name);
    formData.append('files', file, encodedFileName);
  });

  const uploadEndpoint = files.some((file) => file.type.includes('video'))
    ? '/upload/videos'
    : '/upload/images';

  axiosFileUpload
    .post(uploadEndpoint, formData)
    .then((response) => {
      // Handle the response from the server
      if (response.data.fileNames.length === 0) {
        console.error('No files were uploaded');
        return;
      }
      if (response.data.fileNames.length > 0) {
        console.log('Files uploaded successfully', response.data.fileNames);
        const fileLocation = files.some((file) => file.type.includes('video'))
          ? 'https://myway.biznagish.co.il/public/videos/'
          : 'https://myway.biznagish.co.il/public/images/';
        handleAddSrc(index, null, fileLocation + response.data.fileNames[0]);
        return;
      }
    })
    .catch((error) => {
      // Handle errors
      console.error('Error uploading files', error);
    });
};


const handleUploadImage = () => {
  const inputElement = document.createElement('input');
  inputElement.type = 'file';
  inputElement.accept = 'image/*, video/*';
  inputElement.multiple = true;
  inputElement.addEventListener('change', handleFileUpload);
  inputElement.click();
};


  const [showGalleryImages, setShowGalleryImages] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);


    const handleOnClickSelectGallery = (i) => {
     // console.log('handleOnClickSelectGallery',i)
      setGalleryImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[i] = {
          ...updatedImages[i],
          isSelected: !updatedImages[i].isSelected,
        };
        setSelectedImageName(updatedImages[i].src);
        // console.log('updatedImages',updatedImages[i].src)
        return updatedImages;
      });
    }; 
 

    const handleAddImageToBlock = (fileType) => {
      // Logic for retrieving file list from the server
      setShowGalleryImages(true);
    
      axios
        .get(`/upload/${fileType}s`)
        .then((response) => {
          // Handle the response from the server
          const data = response.data.message;
          console.log(data);
          const newGalleryImages = data.map((block, index) => {
            const fileType = block.includes('.mp4') || block.includes('.webm') ? 'video' : 'image';
            return {
              src: `https://myway.biznagish.co.il/public/${fileType}s/${block}`,
              alt: `Media ${index + 1}`,
              isSelected: false,
            };
          });
          console.log(newGalleryImages);
          setGalleryImages(newGalleryImages);
          console.log('the file list is:', response.data);
        })
        .catch((error) => {
          // Handle errors
          console.error('Error getting files', error);
        });
    };
    
  


  return (
    <>
    <div id = "floating-menu" className="floating-menu" style={{ marginBottom: '1%',display: 'flex' }}>
    <button onClick={()=>setIsElementSelected(false)} style={{ fontSize: 'large' }}>
          <RiCloseCircleLine />
        </button>
      
        <button onClick={()=>handleAddImageToBlock(fileType)} style={{ fontSize: 'large' }}>
          <BiLayerPlus />
        </button>
        <button onClick={handleUploadImage} style={{ fontSize: 'large' }}>
          <BiUpload />
        </button>
        <button onClick={() => {
        handleDeleteElement(selectedElement); // Call the delete action
        setSelectedElement({ index1: null, index2: null });
        // setIsListElementSelected(false);
        setIsElementSelected(false);
      }}>
        <BiTrash />
      </button>
    </div>
    {showGalleryImages &&  
        <ExplorerWindow 
        selectImage={handleOnClickSelectGallery}         
        items={galleryImages} 
        setShowGalleryImages={setShowGalleryImages} 
        handleSubmitGallery={handleSubmitGallery}
        PIndex={PIndex}      
        selectedImageName={selectedImageName}
        />    
      }
    </>
  );
};

export default MediaMenu;


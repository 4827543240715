import React from "react";
import { IconButton, Card, Box, Avatar, Stack } from "@mui/material";
import Edit from '@mui/icons-material/Edit';
import Trash from "@mui/icons-material/DeleteForever";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setCornetCompany,
  DeleteCompanyAsync,
  setSelectedCompany,
  updateCompanyStatusAsync
} from "../../../store/slices/companySlice";
import OnOffSwitch from "./OnOffSwitch";
import useToggle from "@rooks/use-toggle";

export default function CompanyBlock({ company }) {
  const dispatch = useDispatch();

  const {
    companyName,
    companyIcon,
    status,
    backgroundColor
  } = company;

  const customToggleFunction = (v) => (v === "active" ? "not_active" : "active");
  const [isCompanyActive, toggleCompanyActive] = useToggle(status, customToggleFunction);


  const handleOnClick = () => {
    const newStatus = customToggleFunction(isCompanyActive);
    toggleCompanyActive(newStatus);
    dispatch(updateCompanyStatusAsync({ companyName, companyIcon, status: newStatus, backgroundColor }));
  };
  
  

  const handleEditClick = () => {
    dispatch(updateCompanyStatusAsync({ companyName, companyIcon, status: isCompanyActive, backgroundColor }));
    dispatch(setSelectedCompany(companyName));
  };

  return (
    <Card sx={{ m: 1, display: "grid" }}>
      <OnOffSwitch state={isCompanyActive} onClick={handleOnClick} />
      <Stack spacing={4} sx={{ m: 4, placeItems: "center" }}>
        <Link
          onClick={() => {
            dispatch(setCornetCompany(companyName));
          }}
          style={{ textAlign: "center", fontSize: "1.5rem", color: "black" }}
          to={"/admin/Costumers/tutorial"}
        >
          <Avatar
            alt={companyName}
            src={companyIcon || "avatar1.jpg"}
            variant="square"
            sx={{
              p: 1,
              m: 1,
              height: 100,
              width: "90%",
              backgroundColor,
              objectFit: "fill",
            }}
          />
          {companyName}
        </Link>
      </Stack>
      <Box dir="LTR" style={{ placeItems: "end", marginBottom: "2rem" }}>
        <IconButton
          onClick={() => {
            console.log("delete", companyName);
            dispatch(DeleteCompanyAsync(companyName));
          }}
        >
          <Trash style={{ justifySelf: "end", color: "red", width: "2rem" }} />
        </IconButton>

        <Link
          style={{ textAlign: "center", fontSize: "1.5rem", color: "black" }}
          to={"/admin/Costumers/editCostumer"} // link to edit page not exist
        >
          <IconButton onClick={handleEditClick}>
            <Edit style={{ justifySelf: "end", color: "blue", width: "2rem" }} />
          </IconButton>
        </Link>
      </Box>
    </Card>
  );
}
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CustomButton = ({ imageSrc, alt, to, rotate, onClick, allowNextPage }) => {
  //  !allowNextPage?'grayscale(1)':'grayscale(0)'
  const [grayscale, setGrayscale] = useState(1);

  useEffect(() => {
    if (allowNextPage) {
      setGrayscale(0);
    } else {
      setGrayscale(1);
    }
  }, [allowNextPage]);

  return (
    <Link to={to} style={{ display: "grid", placeItems: "center",width: "100%",}}>
      {rotate ? (
        <img
        className="custom-button1"
          src={imageSrc}
          alt={alt}
          title={alt}
          style={{ transform: `rotate(${rotate}deg)` }}
          onClick={onClick}
        />
      ) : (
        <img
          className="custom-button1"
          src={imageSrc}
          alt={alt}
          title={alt}
          style={{  filter: `grayscale(${grayscale})` }}
          onClick={onClick}
        />
      )}
    </Link>
  );
};

export default CustomButton;

import React from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const galleryItem = (props) => {
  const { src, alt, isSelected, onClick } = props;
  return (
    <div className="galleryItem__image" onClick={onClick}>
      <img src={src} alt={alt}  style={{width:"150px",height:"150px"}}/>
      <div className="galleryItem__overlay" >
        <button className="galleryItem__icon">
          {isSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
        </button>
      </div>
    </div>

  )
}

export default galleryItem
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../apis/axios';
// import axios from 'axios';
import useAxiosFunction from '../../Hooks/useAxios';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import logo from '../../assets/negishut_logo_colors.png';
import { useDispatch } from 'react-redux';
import { setContentUser } from '../../store/slices/usersSlice';
import { fetchInternshipItems } from '../../store/slices/internshipsSlice';
// imprt images
import backg1 from '../../assets/backg1.jpeg';





import {
  FetchOneCompanyData,
  setCornetCompany,
  GetAllCompanies,
} from '../../store/slices/companySlice';

import { TextField, Button } from '@mui/material';

//styles
const styles = {
  login: {
    margin: 'auto 2% auto 2%',
    padding: '1rem',
    border: '1px solid black',
    borderRadius: '10px',
    backgroundColor: '#e9e5fbb3',
    minWidth: '25vw',
    maxWidth: '500px',
    // boxShadow: '0px 0px 10px #888888',
    boxShadow: 'rgb(136, 136, 136) 0px 0px 10px',
  },
  h1: { display: 'flex', justifyContent: 'right', marginRight: '1rem' },
  form: {
    display: 'grid',
    padding: '1rem',

  },
  img: { marginLeft: '1rem', marginTop: '1rem', width: '30%' }, /////////display: "none"
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
});

const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, error, loading, axiosFetch] = useAxiosFunction();
  const [UserNameTextError, SetUserNameTextError] = useState('');
  const [PasswordTextError, SetPasswordTextError] = useState('');
  const [generalError, SetGeneralError] = useState('');
  const [screenSize, setScreenSize] = useState(window.innerWidth);

useEffect(() => {
  window.addEventListener('resize', () => {
    setScreenSize(window.innerWidth);
  });
  // eslint-disable-next-line
}, [window.innerWidth]);

  const callOnce = useRef(false);
  // const renderAfterCalled = useRef(false);
  callOnce.current = false;

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosFetch({
      axiosInstance: axios,
      method: 'post',
      url: '/auth',
      requestConfig: {
        email: e.target.mailBox.value,
        password: e.target.passwordBox.value,
      },
    });

  };

  const routUser = (role) => {
    switch (role) {
      case 'admin':
        if (screenSize > 1306) navigate('/admin/home')
        else SetGeneralError('מצטערים, כניסת אדמין נתמכת בדסקטופ בלבד');
        break;
      case 'user-admin':
        navigate('/user-admin/home');
        break;
      case 'user':
        navigate('/users');

        break;
      default:
    }
  };

  useEffect(() => {
    if (callOnce.current) return;
    if (data.length === 0) return;  
    document.cookie = `Authorization=${data?.user?.AccessesToken}; path=/; SameSite=None; secure=true`;
    dispatch(setCornetCompany(data?.user?.companyName));
    dispatch(setContentUser(data?.user ? data?.user : null));
    dispatch(FetchOneCompanyData(data?.user?.companyName));
    dispatch(fetchInternshipItems());

    if (data?.user?.role === 'admin') {
      dispatch(GetAllCompanies());
    }

    routUser(data?.user?.role);
    callOnce.current = true;
    // eslint-disable-next-line
  }, [data]);


  // view the errors messages in the ui
  const errorCheck = () => {
    const errorMessages = {
      'Username and password are required': 'אנא מלא את כל השדות',
      'Wrong password': 'סיסמא שגויה',
      'Invalid username': 'שם משתמש שגוי',
      'Company is not active': 'החברה לא פעילה אנא צור קשר עם מנהל המערכת',
      'Internal server error': 'התרחשה שגיאה פנימית בשרת',
      'Network Error': 'התרחשה שגיאת רשת',
    };

    SetPasswordTextError('');
    SetUserNameTextError('');
    SetGeneralError('');

    if (error && errorMessages.hasOwnProperty(error)) {
      const errorMessage = errorMessages[error];
      if (error === 'wrong password') {
        SetPasswordTextError(errorMessage);
      } else if (error === 'Invalid username') {
        SetUserNameTextError(errorMessage);
      } else {
        SetGeneralError(errorMessage);
      }
    }
  };

  useEffect(() => {
  
    errorCheck();
    // eslint-disable-next-line
  }, [error]);


  return (
    <CacheProvider value={cacheRtl}>

      <div style={{
        width: '100vw',
        height: '100vh',
        backgroundPositionX: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `url(${backg1})`,
      
      }}>
        <div
          style={{
            display: 'grid',
            width: '100vw',
            height: '100vh',
            placeItems: 'center',
            backgroundColor: '#FFFFFF91',
            
          }}
        >
          <div style={styles.login}>
            <div style={styles.header}>
              <div style={{ width: '70%' }}>
                <h1 style={styles.h1}>כניסה</h1>

              </div>
              <img style={styles.img} src={logo} alt="logo" />
            </div>
            <form style={styles.form} onSubmit={handleSubmit}>
              <TextField
                id="mailBox"
                fullWidth
                sx={{ height: '3rem', marginTop: '4%', marginBottom: '4%' }}
                autoComplete="username"
                type="email"
                label="שם משתמש או דואר אלקטרוני"
                helperText={UserNameTextError}
              />
              <TextField
                id="passwordBox"
                fullWidth
                sx={{ height: '3rem', marginTop: '4%', marginBottom: '9%' }}
                autoComplete="current-password"
                type="password"
                label="סיסמא"
                helperText={PasswordTextError}
              />
              {loading ? (
                <div>טוען...</div>
              ) : (
                <div style={{ display: 'flex' }}>

                  <Button
                    sx={{ marginTop: '2%', justifySelf: 'end', width: '5rem' }}
                    variant="outlined"
                    type="submit"
                  >
                    אישור
                  </Button>
                  {generalError !== '' ? <div style={{
                    padding: '0.5rem',
                    margin: '0 auto',
                    display: 'grid',
                    placeSelf: 'center',
                    border: '1px solid red',
                  }}><p>{generalError}</p></div> : null}
                </div>
              )}
            </form>

          </div>
        </div>
      </div>
    </CacheProvider>
  );
}

export default Login;
import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  setContentPage,  setTutorial} from '../../../store/slices/tutorialSlice';

import Quiz from './Quiz';
import RouterLinkImgBlock from './htmlComponents/RouterLinkImgBlock';
import StylingMenu from './StylingMenu';
import ImgBlock from './htmlComponents/ImgBlock';
import Video from './htmlComponents/Video';
import TextBlock from './htmlComponents/TextBlock';
import LinkBlock from './htmlComponents/LinkBlock';
import List from './htmlComponents/List';
import './StylingMenu.css';

const TextWithHeader = (props) => {
  const {
    body,
    pageData,
    setPageData,
    pageStyle,
    router,
    answer,
    setAnswer,
    setCurrentPage,
    setAllowNextPage,
    specificInternship,
    setSpecificInternship,
    contenteditable,
    size,
  } = props;

  const dispatch = useDispatch();
  const [updatedPageData, setUpdatedPageData] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [isElementSelected, setIsElementSelected] = useState(false);
  const [isListElementSelected, setIsListElementSelected] = useState(false);

  const tutorialSlides = useSelector((state) => state.tutorial.tutorialSlides);
  const selectedSlide = useSelector((state) => state.tutorial.slideNumber);
         
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);

  const [draggedOverIndexInsideList, setDraggedOverIndexInsideList] = useState(null);
  const [draggedStartedIndex, setDraggedStartedIndex] = useState(null);
  const [draggedStartedListIndex, setDraggedStartedListIndex] = useState(null);

  const [showLink, setShowLink] = useState(false);

  const refs = useRef([]);

  const throttle = (func, limit) => {
    let inThrottle;
    return function () {
      const context = this;
      const args = arguments;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  };

 

  const dragStarted = (e, index) => { 
    setDraggedStartedIndex(index);
  };

  const dragOver = (e, index) => {
    e.preventDefault(); 
    if (draggedOverIndex === index) return;
    setDraggedOverIndex(index);
  };

  const handleDragOverThrottled = throttle(dragOver, 400); // Adjust the limit as needed

  const dragStartedInsideList = (e, index) => { 
    setDraggedStartedListIndex(index);
  };

  const dragOverInsideList = (e, index) => {
    e.preventDefault(); 
    if (draggedOverIndexInsideList === index) return;
    setDraggedOverIndexInsideList(index);
  };
 
  const handleDragOverInsideListThrottled = throttle(dragOverInsideList, 400); // Adjust the limit as needed

  const dragDropped = () => {
    console.log('dragDropped',draggedStartedIndex, draggedOverIndex)
    // replace the dragged element with the dropped element
    const pageDataCopy = { ...pageData };
    const bodyCopy = [...pageDataCopy.body];
    const elementCopy = { ...bodyCopy[draggedStartedIndex] };
    bodyCopy.splice(draggedStartedIndex, 1);
    bodyCopy.splice(draggedOverIndex, 0, elementCopy);
    pageDataCopy.body = bodyCopy;
    // setPageData(pageDataCopy);
    setUpdatedPageData({ ...pageData, body: pageDataCopy.body });
    // dispatch(setContentPage(pageDataCopy));
    setSelectedElement({ index1: null, index2: null });
    setIsListElementSelected(false);
    setIsElementSelected(false);
  };

 

  const dragDroppedInArray = (indexInBody, draggedStartedIndex, draggedOverIndex) => {
    console.log('dragDroppedInArray',indexInBody, draggedStartedIndex, draggedOverIndex)// result: dragDroppedInArray 1 1 0
    if (draggedStartedIndex === null || draggedOverIndex === null) {
      return;
    }
    const pageDataCopy = { ...pageData };
    const bodyCopy = [...pageDataCopy.body];  
    console.log('bodyCopy',bodyCopy)
    const listToHandle = { ...bodyCopy[indexInBody] };
    console.log('listToHandle',listToHandle)
    const textArray = [...listToHandle.items];
    console.log('textArray',textArray)
    const [draggedItem] = textArray.splice(draggedStartedIndex, 1);
    textArray.splice(draggedOverIndex, 0, draggedItem);
    listToHandle.items = textArray;
    bodyCopy[indexInBody] = listToHandle;
    console.log('bodyCopy[indexInBody]',bodyCopy[indexInBody] )
    setUpdatedPageData({ ...pageData, body: bodyCopy });
    
 
    dispatch(setContentPage({ ...pageData, body: bodyCopy }));

    setSelectedElement({ index1: indexInBody, index2: draggedOverIndex });
    setIsListElementSelected(true);
    setIsElementSelected(true);
  };

/*   useEffect(() => {
    if (draggedOverIndex !== null) {dragDroppedInArray(draggedOverIndex)}
  }, [draggedOverIndex]); */
  
  useEffect(() => {
    console.log('useEffect', selectedElement);
  }, [selectedElement]);
  
  const handleAddSrc = (index1, index2, newSrc = null) => {
    const updatedBody = [...body];
    if (updatedBody[index1]) {
      const updatedElement = {
        ...updatedBody[index1].imgs[0],
        src: newSrc !== null ? newSrc : updatedBody[index1].src,
      };
      updatedBody[index1] = updatedElement;
      const innerUpdatedPageData = {
        ...pageData,
        body: updatedBody,
      };
      setUpdatedPageData(innerUpdatedPageData);
      setSelectedElement({ index1, index2 });
      setIsElementSelected(true);
    } else {
      console.log(`Element at index ${index1} does not exist.`);
    }
  };

  const handleInput = (index1, index2, newText ) => { 
    if (!contenteditable) return;
    const updatedBody = [...body];
    const selectedElement = refs.current[index1][index2];
    const isListElement = updatedBody[index1].type === 'uoList' || updatedBody[index1].type === 'oList';
    const updatedElement = { ...updatedBody[index1] };
console.log('handleInput',index1, index2, newText, selectedElement, 'isListElement',isListElement, updatedElement)
    if (!isListElement) {
      const newElement = {
        ...updatedBody[index1],
        text: [newText !== null ? newText : selectedElement.textContent.trim()],
      };
      updatedBody[index1] = newElement;
    } 

    else {
      updatedElement.items = updatedElement.items.map((listItem, i) =>
        (i === index2)
          ? (newText !== null)
            ? { text: newText, style: {} }
            : { text: selectedElement.textContent.trim(), style: {} }
          : listItem
      );
      updatedBody[index1] = updatedElement;
    }
    

    setUpdatedPageData({ ...pageData, body: updatedBody });
    setIsListElementSelected(isListElement);
    setSelectedElement({ index1, index2 });
    setIsElementSelected(true);
  };

  const handleDeleteElement = (elementToDelete) => {
    const newBody = body.filter((element, i) => i !== elementToDelete.index1);
    setUpdatedPageData({ ...pageData, body: newBody });
  };

  const handleLinkInput = (index1, field, value) => {
    const updatedBody = [...body];
    const updatedElement = { ...updatedBody[index1] };

    if (field === 'text') {
      updatedElement.text = value;
    } else if (field === 'src') {
      updatedElement.src = value;
    }

    updatedBody[index1] = updatedElement;
    const linkUpdatedPageData = { ...pageData, body: updatedBody };

    setUpdatedPageData(linkUpdatedPageData);

    const isListElement =
      updatedElement.type === 'uoList' || updatedElement.type === 'oList';
    setIsListElementSelected(isListElement);
    setSelectedElement({ index1, index2: null });
    setIsElementSelected(true);
  };

  const handleStyleChange = (style, value, { index1, index2 }) => {
    const updatedBody = [...body];
    const selectedTextElement = updatedBody[index1];

    if (
      typeof selectedTextElement === 'object' &&
      selectedTextElement !== null
    ) {
      const updatedStyle = {
        ...selectedTextElement.style,
        [style]:
          selectedTextElement.style?.[style] !== value ? value : undefined,
      };

      const updatedTextElement = {
        ...selectedTextElement,
        style: updatedStyle,
      };

      updatedBody[index1] = updatedTextElement;
      const innerUpdatedPageData = {
        ...pageData,
        body: updatedBody,
      };
      setUpdatedPageData(innerUpdatedPageData);
    } else {
      console.log('selectedTextElement is not an object:', selectedTextElement);
    }
  };


// use useEffect to update the pageData state with the updatedPageData state
  useEffect(() => {
    if (contenteditable && updatedPageData) {  
      dispatch(setContentPage(updatedPageData));

        setPageData((prevPageData) => ({...prevPageData, ...updatedPageData,}));
       
        const tempData = tutorialSlides.map((slide, index) => {
          if (selectedSlide === index)
            return updatedPageData;
               else return slide;
        });
        dispatch(setTutorial(tempData));
   
    }
    // eslint-disable-next-line
  }, [updatedPageData]);


  // State update

  const pageBody = body?.map((element, index1) => {
    const { type, text = [], color, style } = element;
    refs.current[index1] = refs.current[index1] || [];

    switch (type) {
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6':
      case 'p':
        return text?.map((textItem, index2) => {
          const isElementSelected =
            selectedElement &&
            selectedElement.index1 === index1 &&
            selectedElement.index2 === index2;

          return (
            <TextBlock
              key={index2}
              refs={refs}
              type={type}
              index1={index1}
              index2={index2}
              textItem={textItem}
              style={style}
              color={color}
              contenteditable={contenteditable}
              body={body}
              handleInput={handleInput}
              handleStyleChange={handleStyleChange}
              selectedElement={selectedElement}
              setIsElementSelected={setIsElementSelected}
              setIsListElementSelected={setIsListElementSelected}
              setSelectedElement={setSelectedElement}
              isElementSelected={isElementSelected}
              isListElementSelected={isListElementSelected}
              handleDeleteElement={handleDeleteElement}
              dragStarted={dragStarted}
              dragOver={handleDragOverThrottled}
              dragDropped={dragDropped}
              draggedOverIndex={draggedOverIndex}
              draggedStartedIndex={draggedStartedIndex}
            />
          );
        });

      case 'link':
        const isElementSelectedProp = selectedElement?.index1 === index1;
        return (
          <LinkBlock
            key={index1}
            index1={index1}
            type={type}
            element={element}
            style={style}
            color={color}
            contenteditable={contenteditable}
            body={body}
            handleInput={handleInput}            
            handleStyleChange={handleStyleChange}
            selectedElement={selectedElement}
            setIsElementSelected={setIsElementSelected}
            setSelectedElement={setSelectedElement}
            setIsListElementSelected={setIsListElementSelected}
            isElementSelected={isElementSelected}
            isListElementSelected={isListElementSelected}
            isElementSelectedProp={isElementSelectedProp}
            handleLinkInput={handleLinkInput}
            handleDeleteElement={handleDeleteElement}
            showLink={showLink}
            setShowLink={setShowLink}
            dragOver={handleDragOverThrottled}
            dragStarted={dragStarted}
            dragDropped={dragDropped}
           
            draggedOverIndex={draggedOverIndex}
            draggedStartedIndex={draggedStartedIndex}
          />
        );
      // outerList may be a duplicate key - need to fix
      case 'uoList':
      case 'oList':
        return (
        //   { src: oList, alt: 'רשימה 1', toAdd: { type: 'oList', items: [{text:'טקסט1',style:{}},{text:'טקסט2',style:{}}] } },
          <>
        <StylingMenu     
        selectedElement={selectedElement}
        setIsElementSelected={setIsElementSelected}
        setSelectedElement={setSelectedElement}
        setIsListElementSelected={setIsListElementSelected}
        handleDeleteElement={handleDeleteElement} 
        elementId={`list-${index1}`}
        elementType={'list'}
    />
            <List
              key={`outerList-${index1}`}
              index1={index1}
              body={body}
              items={element.items}
              type={type === 'uoList' ? 'ul' : 'ol'}
              dir="rtl"
              style={style ? { color, ...style } : { color }}
              contenteditable={contenteditable}
              handleInput={handleInput}
              isSelected={
                isElementSelected && selectedElement.index1 === index1
              }
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
              onFocus={() => {
                setSelectedElement({ index1, index2: null });
                setIsListElementSelected(
                  body[index1].type === 'uoList' ||
                    body[index1].type === 'oList'
                );
                setIsElementSelected(true);
              }}
              pageData = {pageData}
              setUpdatedPageData = {setUpdatedPageData}
              
              dragDropped={dragDropped} // drop the all list
              dragDroppedInArray={dragDroppedInArray} // drop the list item inside the list    
              dragStarted={dragStarted}
              draggedStartedIndex={draggedStartedIndex}
              dragOver={handleDragOverThrottled} 
              dragStartedInsideList = {dragStartedInsideList}
              dragOverInsideList =  {handleDragOverInsideListThrottled} ///need to be fixed to throttled
              draggedOverIndexInsideList = {draggedOverIndexInsideList}
              draggedStartedListIndex = {draggedStartedListIndex}
              setIsElementSelected={setIsElementSelected}
              setIsListElementSelected={setIsListElementSelected}
              isElementSelected={isElementSelected}
              isListElementSelected={isListElementSelected}
              StylingMenu={StylingMenu}
              handleStyleChange={handleStyleChange}
              handleDeleteElement={handleDeleteElement}
             
            />
       </> 
        );

      case 'quiz':
        return (
          <div
            className={contenteditable ? 'edit' : 'margin'}
            key={`outerQuiz-${index1}`} id={`quiz-${index1}`}
            onFocus={() => {
              setIsElementSelected(true);             
            }
            }
          >           
              <StylingMenu     
                selectedElement={selectedElement}
                setIsElementSelected={setIsElementSelected}
                setSelectedElement={setSelectedElement}
                setIsListElementSelected={setIsListElementSelected}
                handleDeleteElement={handleDeleteElement} 
                elementId={`quiz-${index1}`}
                elementType={'quiz'}
      />
            <Quiz
             
              key={`inner-quiz-${index1}`}
              setAllowNextPage={setAllowNextPage}
              QuizBody={element.body}
              pageBody={body}
              correctAnswer={element.correctAnswer}
              answer={answer}
              setAnswer={setAnswer}
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
              PIndex={index1}
              contenteditable={contenteditable}
              style={style ? { color, ...style } : { color }}
              handleInput={(index2, newText) =>
                handleInput(index1, index2, newText)
              }
              items={element.text}
              updatedPageData={updatedPageData}
              setUpdatedPageData={setUpdatedPageData}
              pageData={pageData}           
              setIsListElementSelected={setIsListElementSelected}
              setIsElementSelected={setIsElementSelected}
            />
          </div>
        );

      case 'linkImgBlock':
        return (
          <div
            key={`outerLinkImgBlock-${index1}`}
            className={contenteditable ? 'edit' : null}
          >
            <RouterLinkImgBlock
              element={element}
              setCurrentPage={setCurrentPage}
              specificInternship={specificInternship}
              setSpecificInternship={setSpecificInternship}
              setAllowNextPage={setAllowNextPage}
              router={router}
            />
          </div>
        );

      case 'imgBlock':
        return (
          <ImgBlock
            key={`outerImgBlock-${index1}`}
            style={style}
            index1={index1}
            slideSize={size} // size is the size of the slide - mini or large
            contenteditable={contenteditable}
            element={element}
            handleAddSrc={handleAddSrc}
            setIsListElementSelected={setIsListElementSelected}
            setSelectedElement={setSelectedElement}
            setIsElementSelected={setIsElementSelected}
            isElementSelected={isElementSelected}
            selectedElement={selectedElement}
            handleDeleteElement={handleDeleteElement}
            onClick={() => {              
              setIsElementSelected(true);
              setSelectedElement({ index1, index2: null });
            }}
            pageData={pageData}
            setUpdatedPageData={setUpdatedPageData}
            body={body}
          />
        );
      case 'video':
        return (
          <Video
            key={`outerVideo-${index1}`}
            style={style}
            index1={index1}
            slideSize={size}
            contenteditable={contenteditable}
            element={element}
            setIsListElementSelected={setIsListElementSelected}
            setSelectedElement={setSelectedElement}
            setIsElementSelected={setIsElementSelected}
            isElementSelected={isElementSelected}
            selectedElement={selectedElement}
            body={body}
            pageData={pageData}
            setUpdatedPageData={setUpdatedPageData}
            handleDeleteElement={handleDeleteElement}
            // imageDrop={imageDrop}
            // handleAddSrc={handleAddSrc}
          />
        );

      default:
        return null;
    }
  });

  return (
    <div
      id={size === 'mini' ? 'mini-page-body' : 'page-body'}
      style={pageStyle}
    >
      {pageBody}
    </div>
  );
};

export default TextWithHeader;

import React from "react";
import { Routes, Route } from "react-router-dom";
// import CreateTutorial from "../../pages/admin/CreateTutorial";
import CreateTutorialByText from "./CreateTutorialByText/CreateTutorialByText";
import EditCostumer from "../../pages/admin/EditNewCostumerPage";
import Tutorial from "../../pages/tutorials/App";
import TutorialsTamale from "../tutorials";
import AddNewCostumerPage from "./AddNewCostumerPage";
import CostumersPage from "./CostumersPage";
import Error from "../../pages/error/Error";
import GaugeChart from './components/GaugesPage/GaugesPage';

const AdminRouts = ({isInTemplateEditor, setIsInTemplateEditor}) => {
  React.useEffect(() => {
    setIsInTemplateEditor(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <>
    
    <Routes>
      <Route exact path="home" element={<GaugeChart />} />
      <Route  exact path="tamplets" element={<div> <TutorialsTamale /></div> }/>
      <Route exact path="Costumers" element={<CostumersPage />} />
      <Route exact path="AddCostumer" element={<AddNewCostumerPage />} />
      <Route exact path="Costumers/tutorial" element={<Tutorial />} />
      <Route exact path="Costumers/editCostumer" element={<EditCostumer />} />
      {/* <Route path="addTamplets" element={<CreateTutorial />} /> */}
      <Route exact path="addTampletsByText" element={<CreateTutorialByText isInTemplateEditor ={isInTemplateEditor} setIsInTemplateEditor={setIsInTemplateEditor}/>} />
      <Route path="/*" element={<Error />} />
    </Routes>
    </>
  );
};

export default AdminRouts;

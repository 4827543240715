import { useState } from 'react';
import { BiBold, BiUnderline, BiShow,  BiTrash } from 'react-icons/bi';
import { AiOutlineAlignLeft, AiOutlineAlignCenter, AiOutlineAlignRight, AiOutlineClose } from 'react-icons/ai';
import useContextMenu from "../../../Hooks/useContextMenu";

const StylingMenu = ({  
  handleStyleChange,
  selectedElement,
  setSelectedElement,
  setIsElementSelected,
  setIsListElementSelected,
  handleDeleteElement,
  elementId, 
  elementType,
  showLink,
  setShowLink 
 }) => {
// anchorPoint, isMenuOpen
  const [bold, setBold] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [alignment, setAlignment] = useState('left');
  const [anchorPoint, isMenuOpen] = useContextMenu(elementId);
  const handleColorChange = (e) => {
    const color = e.target.value;
    handleStyleChange('color', color, selectedElement);
  };

  const handleFontSizeChange = (e) => {
    const fontSize = e.target.value + 'px';
    handleStyleChange('fontSize', fontSize, selectedElement);
  };


/*   const handleMove = (e) => {
    const element = e.target.parentElement.parentElement;
    const elementPosition = {
      x: element.offsetLeft,
      y: element.offsetTop,
    };
    // Set the element position if you need it for other purposes
  }; */
  
  if (!isMenuOpen) return null 
  else
  return (
    <div className="floating-menu" 
    style={{position: 'absolute' , top: 0, right: 0 ,zIndex:"9999"}}
    // style={{ top: anchorPoint.y-100, left: anchorPoint.x-225.2 ,zIndex:"9999"}}
    >

      {/* <button id="close" onClick={() => {       
        setSelectedElement({ index1: null, index2: null });
        setIsListElementSelected(false);
        setIsElementSelected(false);
      }}>
        <AiOutlineClose className={alignment === 'right' ? 'active' : ''} />
      </button> */}
   
      {(elementType !== 'quiz') && (elementType !== 'list')  && 
  
      <>
      <label htmlFor="color-picker">
      <span className="hidden-text">Color:</span>
      <input className="color-picker" type="color" id="color-picker" onChange={handleColorChange} />
      </label>
      <label htmlFor="font-size-picker">
        <span className="hidden-text">Font Size:</span>
        <input className="font-size-picker" type="number" id="font-size-picker" min="10" max="50" onChange={handleFontSizeChange} />
      </label>
      <button id ="bold" onClick={() => {
        setBold(!bold);
        handleStyleChange('fontWeight', 'bold', selectedElement);
      }}>
        <BiBold className={bold ? 'active' : ''} />
      </button>
      <button id="underline" onClick={() => {
        setUnderline(!underline);
        handleStyleChange('textDecoration', 'underline', selectedElement);
      }}>
        <BiUnderline className={underline ? 'active' : ''} />
      </button>
      <button id="textAlign-Left" onClick={() => {
        setAlignment('left');
        handleStyleChange('textAlign', 'left', selectedElement);
      }}>
        <AiOutlineAlignLeft className={alignment === 'left' ? 'active' : ''} />
      </button>
      <button id="textAlign-center" onClick={() => {
        setAlignment('center');
        handleStyleChange('textAlign', 'center', selectedElement);
      }}>
        <AiOutlineAlignCenter className={alignment === 'center' ? 'active' : ''} />
      </button>
      <button id="textAlign-right" onClick={() => {
        setAlignment('right');
        handleStyleChange('textAlign', 'right', selectedElement);
      }}>
        <AiOutlineAlignRight className={alignment === 'right' ? 'active' : ''} />
      </button>
      </>}
      <button id="delete-element" onClick={() => {
      
        handleDeleteElement(selectedElement); // Call the delete action
        setSelectedElement({ index1: null, index2: null });
        setIsListElementSelected(false);
        setIsElementSelected(false);
      }}>
        <BiTrash />
      </button>
      {elementType === 'link' && 
      <button id="delete-element" onClick={()=>{setShowLink(!showLink)}}>
      <BiShow />
      </button>}

    </div>
  );


};

export default StylingMenu;
